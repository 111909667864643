import axios from 'axios'

const TESTING = process.env.REACT_APP_DEV === 'testing'
const PRODUCTION = process.env.REACT_APP_DEV === 'production'

let baseURI = 'http://host.docker.internal:3333'
if (TESTING) baseURI = 'http://host.docker.internal:3007'
if (PRODUCTION) baseURI = 'https://www.api.ticketcapital.com.br'

export const api = axios.create({ baseURL: baseURI })
