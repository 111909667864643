import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Load } from "../../components/Load";
import { IEventosVendas } from "../../global/types";
import { api } from "../../services/api";
import QRCode from "react-qr-code";

import { maskCpfCnpj, maskPhone } from "../../utils/masks";

import "./style.css";

export function Ingresso() {
  const { idIngresso } = useParams();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [venda, setVenda] = useState<IEventosVendas>({} as IEventosVendas);

  const loadIngresso = async () => {
    if (!idIngresso || idIngresso.length <= 6) return;

    setLoading(true);
    setTxtLoading("Carregando ingresso...");

    await api.get(`eventos/venda/${idIngresso}`)
      .then(res => {
        if (!res.data)
          return;

        setVenda(res.data);
      })
      .catch(err => {
        if (err.response?.data?.message)
          alert(err.response?.data?.message);
        else
          alert("Erro ao carregar o ingresso. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  useEffect(() => {
    loadIngresso();
  }, []);

  return (
    <div id="pageIngresso">
      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && (
        <>
          <QRCode value={`https://ticketcapital.com.br/adm/ingressos/${venda.id}`} />
          <h4>Evento:</h4>
          <p>{venda.evento?.nome}</p>

          <h4>Setor:</h4>
          <p>{venda.lote?.setor?.nome}</p>

          <h4>Status:</h4>
          <p>
            {venda.status?.includes("paid") && ("Pago")}
            {venda.status?.includes("failed") && ("Não Pago")}
            {venda.status?.includes("waiting_payment") && ("Aguardando pagamento")}
            {venda.entregue && (" (UTILIZADO)")}
          </p>

          <h4>Cliente:</h4>
          {venda.cliente && (
            <>
              <p>{venda.cliente.nome} {maskPhone(venda.cliente.telefone)}</p>
              <p style={{ textAlign: "center", padding: "6px 0" }}>CPF do responsável pelo pagamento:<br />{maskCpfCnpj(`${venda.cpf_cliente}`)}</p>
              <p>E-mail: {(venda.email_cliente)}</p>
            </>
          )}
        </>
      )}

    </div>
  );
}