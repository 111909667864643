import { faCalendarDays, faUser } from '@fortawesome/free-regular-svg-icons'
import {
  faAward,
  faChevronCircleLeft,
  faClipboardList,
  faHashtag,
  faLocationDot,
  faPanorama,
  faTicket,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import imgLogo from '../../assets/logo.png'
import { useAdmContext } from '../../hooks/useAdmContext'
import './style.css'

export function LayoutAdm() {
  const { logout } = useAdmContext()
  const { usuario } = useAdmContext()
  const { pathname } = useLocation()

  const [menuUserOpen, setMenuUserOpen] = useState(false)

  const refChevron = useRef<HTMLDivElement>(null)
  const refNav = useRef<HTMLElement>(null)

  const txtTitle = (path: string): string => {
    if (path.includes('/adm/cidades')) return 'Cidades'
    if (path.includes('/adm/clientes')) return 'Clientes'
    if (path.includes('/adm/eventos')) return 'Eventos'
    if (path.includes('/adm/usuarios')) return 'Usuários'
    if (path.includes('/adm/banners')) return 'Banners'
    if (path.includes('/adm/ingressos')) return 'Ingressos'
    if (path.includes('/adm/socioTorcedor')) return 'Sócio Torcedor'
    if (path.includes('/adm/rifas')) return 'Rifas'
    if (path.includes('/adm/sroteios')) return 'Sorteio'

    return ''
  }

  const handleClickUser = () => {
    setMenuUserOpen((e) => !e)
  }

  const handleLogout = () => {
    if (!window.confirm('Deseja mesmo sair da conta?')) return

    logout()
  }

  const toggleMenu = () => {
    if (refNav.current === null || refChevron.current === null) return

    if (refNav.current.style.width === '0px') {
      refChevron.current.style.transform = 'rotate(0deg)'
      refNav.current.style.width = '150px'
      refNav.current.style.opacity = '1'
    } else {
      refChevron.current.style.transform = 'rotate(180deg)'
      refNav.current.style.width = '0px'
      refNav.current.style.opacity = '0'
    }
  }

  useEffect(() => {
    const div = document.getElementById('menuUser')

    if (!div) return

    if (menuUserOpen) {
      div.style.height = '30px'
      div.style.width = '170px'
    } else {
      div.style.height = '0px'
      div.style.width = '0px'
    }
  }, [menuUserOpen])

  return (
    <>
      <div id="chevronLeft" ref={refChevron} onClick={() => toggleMenu()}>
        <FontAwesomeIcon
          icon={faChevronCircleLeft}
          style={{ fontSize: '20px' }}
        />
      </div>

      <div id="menuUser">
        <p onClick={() => handleLogout()}>Sair</p>
      </div>

      <div className="TopoAdm">
        <div
          className="logo"
          style={{
            background: `url(${imgLogo})`,
            backgroundSize: '80px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />

        <div className="faixaTopo">
          <h2>{txtTitle(pathname)}</h2>

          <div style={{ cursor: 'pointer' }} onClick={() => handleClickUser()}>
            {usuario?.nome}
            <FontAwesomeIcon
              icon={faUser}
              className={`iconUser ${menuUserOpen ? 'toggleOpen' : ''}`}
            />
          </div>
        </div>
      </div>

      <div className="layoutContent">
        <nav ref={refNav}>
          <Link
            to="banners"
            className={`navLink ${
              pathname.includes('/adm/banners') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon icon={faPanorama} style={{ fontSize: '25px' }} />
            <p>Banners</p>
          </Link>

          <Link
            to="cidades"
            className={`navLink ${
              pathname.includes('/adm/cidades') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{ fontSize: '25px' }}
            />
            <p>Cidades</p>
          </Link>

          <Link
            to="clientes"
            className={`navLink ${
              pathname.includes('/adm/clientes') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon icon={faUsers} style={{ fontSize: '25px' }} />
            <p>Clientes</p>
          </Link>

          <Link
            to="sorteios"
            className={`navLink ${
              pathname.includes('/adm/sorteios') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon icon={faAward} style={{ fontSize: '25px' }} />
            <p>Sorteios</p>
          </Link>

          <Link
            to="eventos"
            className={`navLink ${
              pathname.includes('/adm/eventos') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon
              icon={faCalendarDays}
              style={{ fontSize: '25px' }}
            />
            <p>Eventos</p>
          </Link>

          <Link
            to="ingressos"
            className={`navLink ${
              pathname.includes('/adm/ingressos') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon icon={faTicket} style={{ fontSize: '25px' }} />
            <p>Ingressos</p>
          </Link>

          <Link
            to="rifas"
            className={`navLink ${
              pathname.includes('/adm/rifas') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon icon={faHashtag} style={{ fontSize: '25px' }} />
            <p>Rifas</p>
          </Link>

          <Link
            to="socioTorcedor"
            className={`navLink ${
              pathname.includes('/adm/socioTorcedor') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon
              icon={faClipboardList}
              style={{ fontSize: '25px' }}
            />
            <p>Sócio Torcedor</p>
          </Link>

          <Link
            to="usuarios"
            className={`navLink ${
              pathname.includes('/adm/usuarios') ? 'selected' : ''
            }`}
          >
            <FontAwesomeIcon icon={faUser} style={{ fontSize: '25px' }} />
            <p>Usuários</p>
          </Link>
        </nav>

        <div className="layoutContext">
          <Outlet />
        </div>
      </div>
    </>
  )
}
