import './style.css'
import { faEye, faEyeSlash, faImage } from '@fortawesome/free-regular-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Load } from '../../components/Load'
import { IBanners } from '../../global/types'
import { useAdmContext } from '../../hooks/useAdmContext'
import Modal from 'react-modal'

import { defaultModalStyle } from '../../utils/defaultModalStyle'
import { BannerImage } from '../../modals/BannerImage'
import { api } from '../../services/api'

export function BannersAdm() {
  //
  const { checkToken, tokens } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState('')

  const [list, setList] = useState<IBanners[]>([])

  const [modalImagemOpen, setModalImagemOpen] = useState(false)

  const [bannerSelected, setBannerSelected] = useState<IBanners | undefined>()

  const loadBanners = async () => {
    setLoading(true)
    setTxtLoading('Carregando lista de banners...')

    const canLoad = checkToken()
    if (!canLoad) return

    await api
      .get('banners')
      .then((res) => {
        if (!res.data) return

        setList(res.data)
        setBannerSelected(undefined)
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else
          alert('Erro ao carregar lista de banners. Tente novamente mais tarde')
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading('')
      })
  }

  const handleNovo = () => {
    setBannerSelected(undefined)
    setModalImagemOpen(true)
  }

  const handleChangeImg = (item: IBanners) => {
    setBannerSelected(item)
    setModalImagemOpen(true)
  }

  const handleChangeVisible = async (item: IBanners, visivel: boolean) => {
    setLoading(true)
    setTxtLoading('Salvando...')

    // console.log(item);
    // return;
    await api
      .put('banners', { ...item, visivel })
      .then((res) => {
        if (!res.data) return

        loadBanners()
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response.data.message)
        else
          alert(
            'Erro ao alterar visibilidade do banner. Tente novamente mais tarde.'
          )
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading('')
      })
  }

  useEffect(() => {
    if (tokens?.token) loadBanners()
  }, [tokens])

  return (
    <div id="pageBannersAdm">
      <button className="btnNovo btn-green" onClick={() => handleNovo()}>
        <FontAwesomeIcon icon={faCirclePlus} />
        NOVO
      </button>

      <div className="tableContext">
        <div className="headTable">
          <span style={{ width: '120px', justifyContent: 'center' }}>
            Ações
          </span>
          <span style={{ width: '100px', justifyContent: 'center' }}>
            Visível
          </span>
          <span style={{ flex: '1' }}>Banner</span>
        </div>

        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
        )}

        {!loading && (
          <div className="contentTable">
            {list.map((item) => (
              <div className="rowTable" key={item.id}>
                <span>
                  {!item.visivel && (
                    <button
                      className="btn-purple"
                      onClick={() => handleChangeVisible(item, true)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  )}

                  {item.visivel && (
                    <button
                      className="btn-purple"
                      onClick={() => handleChangeVisible(item, false)}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </button>
                  )}

                  <button
                    className="btn-green"
                    onClick={() => handleChangeImg(item)}
                  >
                    <FontAwesomeIcon icon={faImage} />
                  </button>
                </span>
                <span style={{ width: '85px', justifyContent: 'center' }}>
                  {item.visivel ? 'Visível' : 'Não Visível'}
                </span>
                <span style={{ flex: '1' }}>
                  <img
                    src={`${api.defaults.baseURL}/bannerImg/${item.id}.jpg`}
                    height="120px"
                    alt=""
                  />
                </span>
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal
        isOpen={modalImagemOpen}
        style={{
          content: { ...defaultModalStyle.content, height: 355 },
          overlay: { ...defaultModalStyle.overlay }
        }}
      >
        <BannerImage
          closeModal={() => setModalImagemOpen(false)}
          banner={bannerSelected}
          loadBanners={() => loadBanners()}
        />
      </Modal>
    </div>
  )
}
