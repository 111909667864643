import "./style.css"
import { faPix } from "@fortawesome/free-brands-svg-icons"
import { faAddressCard, faIdCard } from "@fortawesome/free-regular-svg-icons"
import {
  faCashRegister,
  faTrash,
  faCreditCard,
  faCalendarDay,
  faAsterisk,
  faCircleDollarToSlot,
  faUndo,
  faAt,
  faDivide,
  faLocationDot,
  faSignsPost,
  faListOl,
  faMap,
  faLocationArrow,
  faStreetView
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Load } from "../../components/Load"
import {
  ICreateCustomerPagarMe,
  ICreateOrderPagarMe,
  ICreateOrderPagarMeCreditCard,
  ICreateOrderPagarMeItems,
  ICreateOrderPagarMePix,
  ICreditCard
} from "../../global/types"
import { useSiteContext } from "../../hooks/useSiteContext"
import { api } from "../../services/api"
import { apiPagarMe } from "../../services/apiPagarMe"
import { currencyFormatter } from "../../utils/currencyFormatter"
import { getTipoSetor } from "../../utils/getTipoSetor"
import { maskCep, maskCpfCnpj, maskCreditCard } from "../../utils/masks"
import { retirarCaracteresEsp } from "../../utils/retirarCaracteresEsp"
import { validate } from "gerador-validador-cpf"

import { listUf } from "../../utils/listUf"
import { ICarrinho } from "../../contexts/SiteContext"

export function Carrinho() {
  const navigate = useNavigate()
  const { carrinho, saveCarrinho, cliente } = useSiteContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [totalCarrinho, setTotalCarrinho] = useState(0)

  const [mostraCarrinho, setMostraCarrinho] = useState(true)
  const [mostraFormasPgto, setMostraFormasPgto] = useState(false)
  const [mostraCartaoCredito, setMostraCartaoCredito] = useState(false)
  const [mostraPix, setMostraPix] = useState(false)

  const [creditCard, setCreditCard] = useState<ICreditCard>({
    cvv: "",
    exp_month: "",
    exp_year: "",
    holder_name: "",
    number: ""
  })
  const [clienteDocumento, setClienteDocumento] = useState("")
  const [clienteEmail, setClienteEmail] = useState("")
  const [qtdParcelas, setQtdParcelas] = useState("1")

  const [endereco, setEndereco] = useState("")
  const [nr, setNr] = useState("")
  const [bairro, setBairro] = useState("")
  const [complemento, setComplemento] = useState("")
  const [cep, setCep] = useState("")
  const [cidade, setCidade] = useState("")
  const [estado, setEstado] = useState<string>("SP")

  const [totalDivisao, setTotalDivisao] = useState(0)

  const deleteCarrinho = (index: number) => {
    if (!window.confirm("Deseja realmente excluir esse item do carrinho?"))
      return

    const newList: ICarrinho[] = []

    carrinho?.map((rs, i) => {
      if (index !== i) newList.push(rs)
    })

    saveCarrinho(newList)
  }

  const handleFinalizar = () => {
    if (totalCarrinho <= 0) return

    if (!cliente?.id || cliente.id.length <= 9)
      navigate("/cliente/login", { state: { goTo: "/carrinho" } })

    setMostraCarrinho(false)
    setMostraFormasPgto(true)
  }

  const handleCreditCard = () => {
    setMostraFormasPgto(false)
    setMostraCartaoCredito(true)
  }

  const handleShowPix = () => {
    setMostraFormasPgto(false)
    setMostraPix(true)
  }

  const handleVoltar = (to: "carrinho" | "formasPgto") => {
    setMostraCartaoCredito(false)
    setMostraPix(false)

    switch (to) {
      case "carrinho":
        setMostraFormasPgto(false)
        setMostraCarrinho(true)
        break

      case "formasPgto":
        setMostraFormasPgto(true)
        break
    }
  }

  const createListItensOrder = (): ICreateOrderPagarMeItems[] => {
    let listItems: ICreateOrderPagarMeItems[] = []
    if (!carrinho) return []

    listItems = carrinho
      .filter((item) => !item.nomeRifa)
      .map((item) => {
        let amount = 0
        let nomeItem = ""
        if (item.setor?.tipo === 3)
          nomeItem = `${item.nomeEvento} - Setor: ${item.setor?.nome} - Lote: ${item.nrLote}`
        else
          nomeItem = `${item.nomeEvento} - Setor: ${item.setor?.nome} (${
            item.setor?.tipo === 1 ? "Mesa" : "Individual"
          }) - Lote: ${item.nrLote}`
        amount = parseInt(
          currencyFormatter(parseFloat(`${item.valor}`)).replace(/\D/g, "")
        )

        return {
          code: `${item.setor?.id.slice(0, 7)}_${item.nrLote}`,
          amount,
          description: nomeItem,
          quantity: 1
        }
      })

    listItems = carrinho
      .filter((item) => item.nomeRifa)
      .map((item, idx) => {
        let amount = 0
        amount = parseInt(
          currencyFormatter(parseFloat(`${item.valor}`)).replace(/\D/g, "")
        )

        return {
          code: `${String(idx).slice(0, 7)}`,
          description: `${item.nomeRifa}`,
          quantity: Number(item.qtde),
          amount
        }
      })

    return listItems
  }

  const createCustomerPagarMe = (
    pgto: "credit_card" | "pix"
  ): ICreateCustomerPagarMe => {
    if (!cliente) return {} as ICreateCustomerPagarMe

    let customer: ICreateCustomerPagarMe = {
      name: pgto === "credit_card" ? creditCard.holder_name : cliente.nome,
      type: "individual",
      document: clienteDocumento.replace(/\D/g, ""),
      email: clienteEmail,
      phones: {
        mobile_phone: {
          country_code: "55",
          area_code: `${cliente.telefone}`.substring(0, 2),
          number: `${cliente.telefone}`.substring(2)
        }
      }
    }

    return customer
  }

  const resolverPay = async (resPagarMe: any) => {
    if (!cliente) return
    if (!carrinho) return

    const isCheckNotTicket = carrinho.filter((item) => item.codigosRifas)
    if (isCheckNotTicket) {
      await Promise.allSettled(
        carrinho.map(async (item) => {
          await api.post("rifas/venda", {
            ...item,
            nr_sorte: item.codigosRifas,
            order_id: resPagarMe.data.id ? resPagarMe.data.id : "ERROR",
            status: resPagarMe.data.status ? resPagarMe.data.status : "ERROR",
            forma_pgto: resPagarMe.forma_pgto,
            id_cliente: cliente.id,
            cpf_cliente: clienteDocumento.replace(/\D/g, ""),
            email_cliente: clienteEmail
          })
        })
      )
      return
    }

    await Promise.allSettled(
      carrinho.map(async (item) => {
        await api.post("eventos/venda", {
          ...item,
          order_id: resPagarMe.data.id ? resPagarMe.data.id : "ERROR",
          status: resPagarMe.data.status ? resPagarMe.data.status : "ERROR",
          forma_pgto: resPagarMe.forma_pgto,
          id_cliente: cliente.id,
          cpf_cliente: clienteDocumento.replace(/\D/g, ""),
          email_cliente: clienteEmail
        })
      })
    )
  }

  const handleCartaoPagar = async () => {
    if (!cliente) return
    if (!carrinho) return

    if (creditCard.exp_year.length !== 4)
      return alert("Preencha o ano da validade do cartão com 4 dígitos.")

    if (
      creditCard.number.length !== 19 ||
      creditCard.cvv.length < 3 ||
      creditCard.exp_month.length !== 2 ||
      creditCard.exp_year.length !== 4 ||
      creditCard.holder_name.length <= 3
    )
      return alert("Preencha todos os dados do cartão corretamente.")

    if (clienteDocumento.length !== 14 || !validate(clienteDocumento))
      return alert("Digite um CPF válido")

    if (clienteEmail.length <= 5 || !clienteEmail.includes("@"))
      return alert("Digite um e-mail válido")

    try {
      setLoading(true)
      setTxtLoading("Enviando pagamento...")

      const pgtoCreditCard: ICreateOrderPagarMeCreditCard[] = []
      pgtoCreditCard.push({
        payment_method: "credit_card",
        credit_card: {
          card: {
            cvv: creditCard.cvv,
            exp_month: parseInt(creditCard.exp_month),
            exp_year: parseInt(creditCard.exp_year),
            holder_name: retirarCaracteresEsp(creditCard.holder_name),
            number: creditCard.number.replace(/[^0-9]/g, ""),
            billing_address: {
              line_1: `${nr
                .replace(",", "")
                .replace(".", "")}, ${endereco.replace(
                ",",
                ""
              )}, ${bairro.replace(",", "")}`,
              line_2: complemento,
              zip_code: cep.replace(/\D/g, ""),
              city: cidade,
              state: estado,
              country: "br"
            }
          },
          installments: parseInt(qtdParcelas),
          recurrence: false,
          statement_descriptor: "TicketCapital"
        }
      })

      const listItems: ICreateOrderPagarMeItems[] = createListItensOrder()

      let newOrderPagarMe: ICreateOrderPagarMe = {
        items: listItems,
        antifraud_enabled: false,
        customer: createCustomerPagarMe("credit_card"),
        payments: pgtoCreditCard
      }

      const resPagarMe = await apiPagarMe.post("orders", { ...newOrderPagarMe })

      if (!resPagarMe.data) throw new Error("Erro ao processar pedido")

      resolverPay({ ...resPagarMe, forma_pgto: "credit_card" })
      navigate(`/waitingPayment/${resPagarMe.data.id}`)
    } catch (err: any) {
      console.log(err)
      if (err.data?.response?.message) alert(err.data.response.message)
      else alert("Erro ao processar pagamento. Tente novamente mais tarde")

      setLoading(false)
      setTxtLoading("")
    }
  }

  const handlePix = async () => {
    if (!cliente) return
    if (!carrinho) return

    if (clienteDocumento.length !== 14 || !validate(clienteDocumento))
      return alert("Digite um CPF válido")

    if (clienteEmail.length <= 5 || !clienteEmail.includes("@"))
      return alert("Digite um e-mail válido")

    try {
      setLoading(true)
      setTxtLoading("Gerando PIX...")

      const listItems: ICreateOrderPagarMeItems[] = createListItensOrder()

      const pgtoPix: ICreateOrderPagarMePix = {
        payment_method: "pix",
        pix: {
          expires_in: "3600", // 1 hora em segundos
          additional_information: [
            {
              name: "Empresa",
              value: "Ticket Capital"
            }
          ]
        }
      }

      let newOrderPagarMe: ICreateOrderPagarMe = {
        items: listItems,
        antifraud_enabled: false,
        customer: createCustomerPagarMe("pix"),
        payments: [pgtoPix]
      }

      try {
        const resPagarMe = await apiPagarMe.post("orders", {
          ...newOrderPagarMe
        })

        if (!resPagarMe.data) throw new Error("Erro ao processar pedido")

        resolverPay({ ...resPagarMe, forma_pgto: "pix" })
        navigate(`/waitingPayment/${resPagarMe.data.id}`)
      } catch (error) {
        console.log(error)
      }
    } catch (err: any) {
      if (err.data?.response?.message) alert(err.data.response.message)
      else alert("Erro ao processar pagamento. Tente novamente mais tarde")

      setLoading(false)
      setTxtLoading("")
    }
  }

  useEffect(() => {
    let nTotal = 0
    let nTotalDivisao = 0

    carrinho?.map((item) => {
      nTotalDivisao =
        item.setor?.dividir_ate && item.setor.dividir_ate > nTotalDivisao
          ? item.setor.dividir_ate
          : nTotalDivisao
      nTotal += parseFloat(`${item.valor}`)
    })

    setTotalDivisao(nTotalDivisao || 1)
    setTotalCarrinho(nTotal)
  }, [carrinho])

  return (
    <div id="pageCarrinho">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && (
        <>
          {!mostraFormasPgto && (
            <>
              <h2>Carrinho</h2>

              {carrinho?.map((item, i) => (
                <div className="cardCarrinho" key={`car-${i}`}>
                  <div>
                    {mostraCarrinho && (
                      <button
                        onClick={() => deleteCarrinho(i)}
                        style={{ background: "#bf291a" }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>
                  <div>
                    <h3>{item.qtde || 1}x</h3>
                  </div>
                  <div>
                    {currencyFormatter(
                      parseFloat(`${Number(item.valor) * (item.qtde || 1)}`)
                    )}
                  </div>
                  <div>
                    {item.nomeRifa && (
                      <p>
                        <b>{item.nomeRifa}</b>
                      </p>
                    )}
                    {item.nomeEvento && (
                      <p>
                        <b>{item.nomeEvento}</b>
                      </p>
                    )}
                    {item.setor?.nome && (
                      <p>
                        <b>Setor:</b> {item.setor?.nome} (
                        {item.setor?.tipo ? getTipoSetor(item.setor?.tipo) : ""}
                        )
                      </p>
                    )}
                    {item.nrLote && (
                      <p>
                        <b>Lote:</b> {item.nrLote}
                      </p>
                    )}
                    {item.nr_lugar && item?.nr_lugar > 0 && (
                      <p>
                        <b>
                          Nrº da {item.setor?.tipo === 3 ? "Vaga" : "Mesa"}:
                        </b>{" "}
                        {item.nr_lugar}
                      </p>
                    )}
                    <p>
                      <b>Valor:</b>{" "}
                      {currencyFormatter(parseFloat(`${item.valor}`))}
                    </p>
                    {item.codigosRifas && (
                      <p>
                        <small style={{ fontStyle: "italic" }}>
                          CÓDIGOS DA SORTE: <b>{item.codigosRifas}</b>
                        </small>
                      </p>
                    )}
                  </div>
                </div>
              ))}

              <div className="faixaTotal">
                <p>
                  Total: {currencyFormatter(parseFloat(`${totalCarrinho}`))}
                </p>
                <div className="btnContext">
                  {mostraCarrinho && (
                    <>
                      {!carrinho?.map((item) => item?.nomeRifa !== "")
                        .length && (
                        <button
                          onClick={() => navigate("/eventos")}
                          className="btnComprarMais"
                        >
                          <FontAwesomeIcon icon={faUndo} />
                          COMPRAR MAIS
                        </button>
                      )}

                      <button onClick={() => handleFinalizar()}>
                        <FontAwesomeIcon icon={faCashRegister} />
                        FINALIZAR COMPRA
                      </button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {mostraFormasPgto && (
            <>
              <h2>Formas de Pagamento</h2>
              <div className="formasPgto">
                <div className="cardPgto" onClick={() => handleCreditCard()}>
                  <FontAwesomeIcon icon={faCreditCard} />
                  <p>Cartão de Crédito</p>
                </div>
                <div className="cardPgto" onClick={() => handleShowPix()}>
                  <FontAwesomeIcon icon={faPix} />
                  <p>PIX</p>
                </div>
              </div>

              <button
                className="btnVoltar"
                onClick={() => handleVoltar("carrinho")}
                style={{ margin: "0 auto" }}
              >
                <FontAwesomeIcon icon={faUndo} />
                VOLTAR
              </button>
            </>
          )}

          {mostraCartaoCredito && (
            <>
              <h2>Cartão de Crédito</h2>
              <div className="divForm">
                <div className="inputForm">
                  <FontAwesomeIcon icon={faAddressCard} />
                  <input
                    value={clienteDocumento}
                    onChange={(e) =>
                      setClienteDocumento(maskCpfCnpj(e.target.value))
                    }
                    placeholder="CPF"
                    type="tel"
                    maxLength={14}
                  />
                </div>

                <div className="inputForm">
                  <FontAwesomeIcon icon={faAt} />
                  <input
                    value={clienteEmail}
                    onChange={(e) => setClienteEmail(e.target.value)}
                    placeholder="E-Mail"
                    type="email"
                  />
                </div>

                <div className="inputForm">
                  <FontAwesomeIcon icon={faCreditCard} />
                  <input
                    value={creditCard.number}
                    onChange={(e) =>
                      setCreditCard((s) => ({
                        ...s,
                        number: maskCreditCard(e.target.value)
                      }))
                    }
                    placeholder="Número do cartão"
                    type="tel"
                    maxLength={19}
                  />
                </div>

                <div className="inputForm">
                  <FontAwesomeIcon icon={faIdCard} />
                  <input
                    value={creditCard.holder_name}
                    onChange={(e) =>
                      setCreditCard((s) => ({
                        ...s,
                        holder_name: e.target.value
                      }))
                    }
                    placeholder="Nome do títular do cartão"
                  />
                </div>

                <div className="inputForm">
                  <FontAwesomeIcon icon={faAsterisk} />
                  <input
                    value={creditCard.cvv}
                    onChange={(e) =>
                      setCreditCard((s) => ({ ...s, cvv: e.target.value }))
                    }
                    placeholder="CVV"
                    type="tel"
                    maxLength={4}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                    width: "300px"
                  }}
                >
                  <p
                    style={{
                      width: "100%",
                      fontWeight: "600",
                      padding: "0px 10px"
                    }}
                  >
                    Validade do cartão: <small>(ex: 09/2030)</small>{" "}
                  </p>
                  <div
                    className="inputForm"
                    style={{ flex: 1, width: "145px" }}
                  >
                    <FontAwesomeIcon icon={faCalendarDay} />
                    <input
                      value={creditCard.exp_month}
                      onChange={(e) =>
                        setCreditCard((s) => ({
                          ...s,
                          exp_month: e.target.value
                        }))
                      }
                      placeholder="DD"
                      type="number"
                      max={12}
                    />
                  </div>

                  <div
                    className="inputForm"
                    style={{ flex: 1, width: "145px" }}
                  >
                    <FontAwesomeIcon icon={faCalendarDay} />
                    <input
                      value={creditCard.exp_year}
                      onChange={(e) =>
                        setCreditCard((s) => ({
                          ...s,
                          exp_year: e.target.value
                        }))
                      }
                      placeholder="AAAA"
                      type="number"
                      max={9999}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                    width: "300px"
                  }}
                >
                  <p
                    style={{
                      width: "100%",
                      fontWeight: "600",
                      padding: "0px 10px"
                    }}
                  >
                    Dividir em:
                  </p>
                  <div className="inputForm">
                    <FontAwesomeIcon icon={faDivide} />
                    <select
                      value={qtdParcelas}
                      onChange={(e) => setQtdParcelas(e.target.value)}
                    >
                      {Array.apply(0, Array(totalDivisao)).map((item, i) => (
                        <option key={`dvInstallments-${i}`} value={i + 1}>
                          {i + 1}x
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                    width: "300px"
                  }}
                >
                  <p
                    style={{
                      width: "100%",
                      fontWeight: "600",
                      padding: "0px 10px"
                    }}
                  >
                    Endereço de Cobrança:
                  </p>
                  <div className="inputForm">
                    <FontAwesomeIcon icon={faLocationArrow} />
                    <input
                      value={endereco}
                      onChange={(e) => setEndereco(e.target.value)}
                      placeholder="Endereço de cobrança"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                    width: "300px"
                  }}
                >
                  <div
                    className="inputForm"
                    style={{ flex: 1, width: "145px" }}
                  >
                    <FontAwesomeIcon icon={faListOl} />
                    <input
                      value={nr}
                      onChange={(e) => setNr(e.target.value)}
                      placeholder="Número"
                    />
                  </div>

                  <div
                    className="inputForm"
                    style={{ flex: 1, width: "145px" }}
                  >
                    <FontAwesomeIcon icon={faSignsPost} />
                    <input
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                      placeholder="Bairro"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                    width: "300px"
                  }}
                >
                  <div className="inputForm">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <input
                      value={complemento}
                      onChange={(e) => setComplemento(e.target.value)}
                      placeholder="Complemento"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                    width: "300px"
                  }}
                >
                  <div className="inputForm">
                    <FontAwesomeIcon icon={faMap} />
                    <input
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                      placeholder="Cidade"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                    width: "300px"
                  }}
                >
                  <div className="inputForm">
                    <FontAwesomeIcon icon={faStreetView} />
                    <select
                      value={estado}
                      onChange={(e) => setEstado(e.target.value)}
                      style={{ padding: "0px" }}
                    >
                      {listUf.map((item) => (
                        <option value={`${item.uf}`}>{item.nome}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    flexWrap: "wrap",
                    width: "300px"
                  }}
                >
                  <div className="inputForm">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <input
                      value={cep}
                      onChange={(e) => setCep(maskCep(e.target.value))}
                      placeholder="CEP"
                      type="tel"
                      maxLength={9}
                    />
                  </div>
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <button
                    className="btnVoltar"
                    onClick={() => handleVoltar("formasPgto")}
                  >
                    <FontAwesomeIcon icon={faUndo} />
                    VOLTAR
                  </button>

                  <button onClick={() => handleCartaoPagar()}>
                    <FontAwesomeIcon icon={faCircleDollarToSlot} />
                    PAGAR
                  </button>
                </div>
              </div>
            </>
          )}

          {mostraPix && (
            <>
              <h2>PIX</h2>
              <div className="divForm">
                <div className="inputForm">
                  <FontAwesomeIcon icon={faAddressCard} />
                  <input
                    value={clienteDocumento}
                    onChange={(e) =>
                      setClienteDocumento(maskCpfCnpj(e.target.value))
                    }
                    placeholder="CPF"
                    type="tel"
                    maxLength={14}
                  />
                </div>

                <div className="inputForm">
                  <FontAwesomeIcon icon={faAt} />
                  <input
                    value={clienteEmail}
                    onChange={(e) => setClienteEmail(e.target.value)}
                    placeholder="E-Mail"
                    type="email"
                  />
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <button
                    className="btnVoltar"
                    onClick={() => handleVoltar("formasPgto")}
                  >
                    <FontAwesomeIcon icon={faUndo} />
                    VOLTAR
                  </button>

                  <button onClick={() => handlePix()}>
                    <FontAwesomeIcon icon={faCircleDollarToSlot} />
                    PAGAR
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
