import { InputHTMLAttributes, useRef } from 'react'

import {
  maskCep,
  maskPhone,
  maskCurrency,
  maskCpfCnpj,
  maskDate,
  maskHour
} from '../../utils/masks'

import './style.css'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  mask: 'cep' | 'phone' | 'currency' | 'cpf_cnpj' | 'date' | 'hour' | 'none'
  inputMaskChange: (v: string) => void
}

export function Input({ mask, inputMaskChange, ...rest }: Props) {
  const refInput = useRef<HTMLInputElement>(null)

  const handleFocus = () => {
    if (refInput.current != null) refInput.current.select()
  }

  function handleChange(text: string) {
    let value = ''
    switch (mask) {
      case 'cep':
        value = maskCep(text)
        inputMaskChange(value)
        break
      case 'currency':
        value = maskCurrency(text)
        inputMaskChange(value)
        break
      case 'phone':
        value = maskPhone(text)
        inputMaskChange(value)
        break
      case 'cpf_cnpj':
        value = maskCpfCnpj(text)
        inputMaskChange(value)
        break
      case 'date':
        value = maskDate(text)
        inputMaskChange(value)
        break
      case 'hour':
        value = maskHour(text)
        inputMaskChange(value)
        break
      default:
        value = text
        inputMaskChange(text)
        break
    }
  }

  return (
    <input
      ref={refInput}
      onFocus={handleFocus}
      className="customInput"
      onChange={(e) => handleChange(e.target.value)}
      {...rest}
    />
  )
}
