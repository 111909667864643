export function getTipoSetor(tipo: number): string {
  switch (tipo) {
    case 1:
      return "Mesa"
    case 2:
      return "Individual"
    case 3:
      return "Posição";

    default:
      return ""
  }
}