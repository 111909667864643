import { useEffect, useState, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../hooks/useSiteContext";
import { maskPhone } from "../../utils/masks";
import "./style.css";

type IParams = {
  children: ReactNode;
}

export function MinhaContaLayout(props: IParams) {
  const { cliente, contextLoaded } = useSiteContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const verificaCliente = async () => {
    if (!contextLoaded)
      return;

    if (!cliente?.id)
      navigate("/cliente/login", { state: { goTo: "/minhaConta" }, replace: true });
  };

  useEffect(() => {
    verificaCliente();
  }, [cliente, contextLoaded]);

  return (
    <div className="minhaConta">
      <div className="menu">
        <p className="cliName">{cliente?.nome}</p>
        <p className="cliPhone">{maskPhone(`${cliente?.telefone}`)}</p>

        <hr style={{ margin: "8px" }} />

        <nav>
          <Link to="/meusIngressos">Meus Ingresso</Link>
          <Link to="/socioTorcedor">Sócio Torcedor</Link>
        </nav>

      </div>

      <div className="content">
        {props.children}
      </div>
    </div>
  );
}