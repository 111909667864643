import { faEdit, faSave } from "@fortawesome/free-regular-svg-icons";
import { faTimes, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { ICreateEventoSetorLote, IEventosSetor, IEventosSetorLote } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { convertDataHora, convertDateToBr, getHourMin } from "../../utils/dateProvider";
import { dynamicSort } from "../../utils/dynamicSort";

import "./style.css";

type IProps = {
  closeModal: () => void;
  attListLotes: (newList: IEventosSetorLote[]) => void;
  setor: IEventosSetor;
}

export function EventoLotes({ closeModal, setor, attListLotes }: IProps) {
  const { checkToken } = useAdmContext();

  const [list, setList] = useState<IEventosSetorLote[]>([]);

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [tipoForm, setTipoForm] = useState<"New" | "Edit">("New");

  const [dataCad, setDataCad] = useState("");
  const [horaCad, setHoraCad] = useState("");
  const [valorCad, setValorCad] = useState("");
  const [taxaCad, setTaxaCad] = useState("");
  const [cadastro, setCadastro] = useState<ICreateEventoSetorLote>({} as ICreateEventoSetorLote);

  const clearCampos = () => {
    setDataCad("");
    setHoraCad("");
    setValorCad("");
    setTaxaCad("");
    setCadastro({} as ICreateEventoSetorLote);
  }

  const handleCancelEdit = () => {
    setTipoForm("New");
    clearCampos();
  }

  const handleDelete = async (item: IEventosSetorLote) => {
    if (!window.confirm(`Deseja realmente excluir este lote?`))
      return;

    setLoading(true);
    setTxtLoading("Excluindo lote...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    try {
      await api.delete("eventos/lote", { data: { id: item.id } });

      const newList: IEventosSetorLote[] = [];
      list.forEach(rs => {
        if (rs.id !== item.id)
          newList.push(rs);
      });

      setList(newList);

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao excluir lote. Tente novamente mais tarde");

    } finally {
      setLoading(false);
      setTxtLoading("");
    }
  }

  const handleEdit = (item: IEventosSetorLote) => {
    setTipoForm("Edit");
    setCadastro({
      id: item.id,
      data: item.data,
      id_evento_setor: item.id_evento_setor,
      qtd_total: item.qtd_total,
      taxa_adm: item.taxa_adm,
      valor: item.valor
    });


    setValorCad(currencyFormatter(parseFloat(`${item.valor}`), {
      significantDigits: 2,
      thousandsSeparator: ".",
      decimalSeparator: ",",
      symbol: "",
    }));
    setTaxaCad(currencyFormatter(parseFloat(`${item.taxa_adm}`), {
      significantDigits: 2,
      thousandsSeparator: ".",
      decimalSeparator: ",",
      symbol: "",
    }));

    setDataCad(convertDateToBr(item.data));
    setHoraCad(getHourMin(item.data));
  }

  const handleSalvar = async () => {

    if (dataCad.length !== 10)
      return alert("Preencha a data corretamente");

    if (horaCad.length !== 5)
      return alert("Peencha a hora corretamente");

    if (valorCad.length <= 0)
      return alert("Preencha o valor");

    setLoading(true);
    setTxtLoading("Salvando lote...");

    try {
      const req = await api.post("eventos/lote", {
        ...cadastro,
        id_evento_setor: setor.id,
        data: convertDataHora(`${dataCad} ${horaCad}`),
        qtd_total: !cadastro.qtd_total || cadastro.qtd_total <= 0 ? 1 : cadastro.qtd_total,
        taxa_adm: taxaCad.length >= 1 ? parseFloat(taxaCad.replace(".", "").replace(",", ".")) : 0,
        valor: parseFloat(valorCad.replace(".", "").replace(",", "."))
      });

      if (!req.data)
        throw new Error("Erro ao recuperar dados do Servidor.");

      const newCad = req.data as IEventosSetorLote;

      if (tipoForm === "New")
        setList(e => [newCad, ...e]);
      else {
        const newList: IEventosSetorLote[] = [];
        list.forEach(item => {
          if (item.id === newCad.id)
            newList.push(newCad);
          else
            newList.push(item);
        })
        setList(newList)
      }

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao salvar lote. Tente novamente mais tarde");
    } finally {
      clearCampos();
      setLoading(false);
      setTxtLoading("");
    }

    const canLoad = checkToken();
    if (!canLoad)
      return;
  }

  useEffect(() => {
    if (setor?.lotes)
      setList(setor.lotes);
  }, [setor]);

  useEffect(() => {
    if (list.length > 0)
      attListLotes(list);
  }, [list]);

  return (
    <div id="modalEventoLotes">
      <div className="title">
        <p>Lotes: {setor?.nome}</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="divFormModal">
              <span style={{ width: "32%" }}>
                <label>Data de início das vendas</label>
                <Input
                  mask="date"
                  value={dataCad}
                  inputMaskChange={(v) => setDataCad(v)}
                  maxLength={10}
                />
              </span>
              <span style={{ width: "32%" }}>
                <label>Hora de início das vendas</label>
                <Input
                  mask="hour"
                  value={horaCad}
                  inputMaskChange={(v) => setHoraCad(v)}
                  maxLength={5}
                />
              </span>
              <span style={{ width: "32%" }}>
                <label>Qtd Total</label>
                <Input
                  mask="none"
                  value={cadastro.qtd_total ? cadastro.qtd_total : 0}
                  inputMaskChange={(v) => setCadastro(e => ({ ...e, qtd_total: parseInt(v) }))}
                  type="number"
                  min={1}
                />
              </span>
              <span style={{ width: "49%" }}>
                <label>Valor</label>
                <Input
                  mask="currency"
                  value={valorCad}
                  inputMaskChange={(v) => setValorCad(v)}
                />
              </span>
              <span style={{ width: "49%" }}>
                <label>Taxa Adm</label>
                <Input
                  mask="currency"
                  value={taxaCad}
                  inputMaskChange={(v) => setTaxaCad(v)}
                />
              </span>
            </div>

            <div className="containerBtns">
              <button className="btn-green" onClick={() => handleSalvar()}>
                <FontAwesomeIcon icon={faSave} />
                {`${tipoForm === "New" ? "CADASTRAR" : "SALVAR"}`}
              </button>

              {tipoForm === "Edit" && (
                <button className="btn-red" onClick={() => handleCancelEdit()}>
                  <FontAwesomeIcon icon={faUndo} />
                  CANCELAR EDIÇÃO
                </button>
              )}

            </div>

            <div className="headTable">
              <span style={{ width: "85px" }}></span>
              <span style={{ flex: 1 }}>Data/Hora Início</span>
              <span style={{ flex: 1 }}>Qtd Total</span>
              <span style={{ flex: 1 }}>Valor</span>
              <span style={{ flex: 1 }}>Taxa Adm</span>
            </div>

            <div className="contentTable">
              {list.sort(dynamicSort("created_at")).map(item => {
                if (!item.excluir) {
                  return (
                    <div className="rowTable" key={item.id}>
                      <span style={{ width: "85px" }}>
                        <button className="btn-red" onClick={() => handleDelete(item)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                        <button className="btn-blue" onClick={() => handleEdit(item)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </span>
                      <span style={{ flex: 1 }}>{convertDateToBr(item.data)} {getHourMin(item.data)}</span>
                      <span style={{ flex: 1 }}>{item.qtd_total}</span>
                      <span style={{ flex: 1 }}>{currencyFormatter(parseFloat(`${item.valor}`))}</span>
                      <span style={{ flex: 1 }}>{currencyFormatter(parseFloat(`${item.taxa_adm}`))}</span>
                    </div>
                  )
                }
              })}
            </div>

          </>
        )}

      </div>

    </div>
  )
}