import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { LayoutAdm } from './components/LayoutAdm'
import { LayoutSite } from './components/LayoutSite'
import { AdmContextProvider } from './contexts/AdmContext'
import { SiteContextProvider } from './contexts/SiteContext'
import { CidadesAdm } from './pages/CidadesAdm'
import { CidadesFormAdm } from './pages/CidadesFormAdm'
import { ClientesAdm } from './pages/ClientesAdm'
import { EventoDetalhes } from './pages/EventoDetalhes'
import { EventosAdm } from './pages/EventosAdm'
import { EventosFormAdm } from './pages/EventosFormAdm'
import { Home } from './pages/Home'
import { LoginAdm } from './pages/LoginAdm'
import { UsuarioFormAdm } from './pages/UsuarioFormAdm'
import { UsuariosAdm } from './pages/UsuariosAdm'
import { LoginCliente } from './pages/LoginCliente'
import { Carrinho } from './pages/Carrinho'
import { EventosPorCidade } from './pages/EventosPorCidade'
import { AguardandoPgto } from './pages/AguardandoPgto'
import { BannersAdm } from './pages/BannersAdm'
import { MeusIngressos } from './pages/MeusIngressos'
import { IngressosAdm } from './pages/IngressosAdm'
import { Ingresso } from './pages/Ingresso'
import { SocioTorcedorAdm } from './pages/SocioTorcedorAdm'
import { MinhaConta } from './pages/MinhaConta'
import { SocioTorcedor } from './pages/SocioTorcedor'
import { Load } from './components/Load'
import { SorteioChuteira } from './pages/SorteioChuteira'
import SorteioChuteiraAdm from './pages/SorteioChuteiraAdm'

const RifasAdm = lazy(() => import('./pages/RifasAdm'))
const RifasFormAdm = lazy(() => import('./pages/RifasFormAdm'))
const Rifas = lazy(() => import('./pages/Rifas'))

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Load txtLoading="Carregando" options={{ width: 150, height: 150 }} />
        }
      >
        <SiteContextProvider>
          <Routes>
            <Route path="/" element={<LayoutSite />}>
              <Route path="/" element={<Home />} />
              <Route path="/carrinho" element={<Carrinho />} />
              <Route path="/cliente/login" element={<LoginCliente />} />

              <Route path="/minhaConta" element={<MinhaConta />} />
              <Route path="/meusIngressos" element={<MeusIngressos />} />
              <Route path="/ingresso/:idIngresso" element={<Ingresso />} />

              <Route path="/evento/:idEvento" element={<EventoDetalhes />} />

              <Route
                path="/cidades"
                element={<EventosPorCidade typePage="Cidades" />}
              />
              <Route
                path="/cidades/:idCidade"
                element={<EventosPorCidade typePage="Cidades" />}
              />
              <Route
                path="/cidades/:idCidade/:nrPag"
                element={<EventosPorCidade typePage="Cidades" />}
              />

              <Route
                path="/eventos"
                element={<EventosPorCidade typePage="Todas" />}
              />

              <Route
                path="/waitingPayment/:orderId"
                element={<AguardandoPgto />}
              />

              <Route path="/socioTorcedor" element={<SocioTorcedor />} />

              <Route path="/rifas/:idRifa" element={<Rifas />} />
              <Route path="/sorteio-chuteira" element={<SorteioChuteira />} />
            </Route>
          </Routes>
        </SiteContextProvider>

        <AdmContextProvider>
          <Routes>
            <Route path="login" element={<LoginAdm />} />
            <Route path="adm" element={<LayoutAdm />}>
              <Route path="cidades" element={<CidadesAdm />} />
              <Route
                path="cidades/novo"
                element={<CidadesFormAdm tipoForm="New" />}
              />
              <Route
                path="cidades/:idCidade"
                element={<CidadesFormAdm tipoForm="Edit" />}
              />

              <Route path="clientes" element={<ClientesAdm />} />
              <Route path="clientes/:id" element={null} />

              <Route path="usuarios" element={<UsuariosAdm />} />
              <Route
                path="usuarios/novo"
                element={<UsuarioFormAdm tipoForm="New" />}
              />
              <Route
                path="usuarios/:idUsuario"
                element={<UsuarioFormAdm tipoForm="Edit" />}
              />

              <Route path="rifas" element={<RifasAdm />} />
              <Route
                path="rifas/:idRifa"
                element={<RifasFormAdm tipoForm="Edit" />}
              />

              <Route path="eventos" element={<EventosAdm />} />
              <Route path="sorteios" element={<SorteioChuteiraAdm />} />
              <Route
                path="eventos/novo"
                element={<EventosFormAdm tipoForm="New" />}
              />
              <Route
                path="eventos/:idEvento"
                element={<EventosFormAdm tipoForm="Edit" />}
              />

              <Route path="ingressos" element={<IngressosAdm />} />
              <Route path="ingressos/:idIngresso" element={<IngressosAdm />} />

              <Route path="banners" element={<BannersAdm />} />

              <Route path="socioTorcedor" element={<SocioTorcedorAdm />} />
            </Route>
          </Routes>
        </AdmContextProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
