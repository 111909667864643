// import Lottie from "lottie-react-web"
import { useEffect, useRef, useState } from "react"
import { useSiteContext } from "../../hooks/useSiteContext"

import paymentFailed from "../../assets/paymentFailed.json"
import paymentInprogress from "../../assets/paymentInprogress.json"
import paymentSuccess from "../../assets/paymentSuccess.json"

import "./style.css"
import { useParams } from "react-router-dom"
import { apiPagarMe } from "../../services/apiPagarMe"

type IParams = {
  orderId: string
}

type IPayment = {
  pixEnable: boolean
  inprogress: boolean
  successfully: boolean
}

export function AguardandoPgto() {
  const { socket, saveCarrinho } = useSiteContext()
  const { orderId } = useParams() as IParams

  const refIconCopied = useRef(null)

  // const [] = useState([])
  const [payment, setPayment] = useState<IPayment>({
    inprogress: true,
    successfully: false,
    pixEnable: false
  })
  const [imgPix, setImgPix] = useState("")
  const [codePix, setCodePix] = useState("")

  const handleCopyPixCode = () => {
    navigator.clipboard.writeText(codePix)

    if (refIconCopied.current === null) return

    const ref: any = refIconCopied.current
    ref.style.opacity = "1"

    setTimeout(() => {
      ref.style.opacity = "0"
    }, 2000)
  }

  const loadPedido = async () => {
    await apiPagarMe
      .get(`orders/${orderId}`)
      .then((res) => {
        if (!res.data) return

        const status = res.data.status
        if (status === "failed") {
          setPayment({
            inprogress: false,
            successfully: false,
            pixEnable: false
          })
        } else if (status === "paid") {
          saveCarrinho([])
          setPayment({
            inprogress: false,
            successfully: true,
            pixEnable: false
          })
        } else if (status === "waiting_payment" || status === "pending") {
          saveCarrinho([])
          let isPix: boolean = false

          if (res.data.charges[0].payment_method === "pix") {
            isPix = true
            setImgPix(res.data.charges[0].last_transaction.qr_code_url)
            setCodePix(res.data.charges[0].last_transaction.qr_code)
          }

          setPayment({
            inprogress: true,
            successfully: false,
            pixEnable: isPix
          })

          socket.on(orderId, (data) => {
            setPayment({
              inprogress:
                data.type === "order.paid" || data.type === "paid"
                  ? false
                  : true,
              successfully: data.type === "order.paid" || data.type === "paid",
              pixEnable: isPix
            })
          })
        }
      })
      .catch((err) => {
        alert(
          "Erro ao carregar registro de pagamento. Tente novamente mais tarde"
        )
      })
  }

  useEffect(() => {
    if (!orderId || orderId.length <= 9) return

    loadPedido()
  }, [orderId])

  return (
    <div id="pageAguardandoPgto">
      {payment.inprogress && (
        <>
          <h3>AGUARDANDO CONFIRMAÇÃO DE PAGAMENTO</h3>

          {payment.pixEnable && imgPix.length > 9 && (
            <>
              <img src={imgPix} style={{ width: "200px" }} />
              <p
                style={{ cursor: "pointer" }}
                onClick={() => handleCopyPixCode()}
              >
                ou clique aqui para copiar o código
              </p>
              <div className="divCopied" ref={refIconCopied}>
                copiado
              </div>
            </>
          )}

          {/* {!payment.pixEnable && (
            <Lottie
              options={{ animationData: paymentInprogress }}
              width="30%"
            />
          )} */}
        </>
      )}

      {!payment.inprogress && payment.successfully && (
        <>
          <h3>PAGAMENTO APROVADO</h3>
          {/* <Lottie
            options={{ animationData: paymentSuccess, loop: false }}
            width="30%"
          /> */}
        </>
      )}

      {!payment.inprogress && !payment.successfully && (
        <>
          <h3>
            PAGAMENTO NÃO APROVADO PELA OPERADORA, TENTE NOVAMENTE DE OUTRA
            MANEIRA OU TENTE MAIS TARDE. OBRIGADO.
          </h3>
          {/* <Lottie
            options={{ animationData: paymentFailed, loop: false }}
            width="30%"
          /> */}
        </>
      )}
    </div>
  )
}
