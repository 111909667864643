import { faUser } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons"
import {
  faBars,
  faLocationDot,
  faSearch,
  faTimes
} from "@fortawesome/free-solid-svg-icons"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import PackageJson from "../../../package.json"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import imgLogo from "../../assets/logo_circle.png"

import bgImg from "../../assets/bg.jpg"

import "./style.css"
import { useSiteContext } from "../../hooks/useSiteContext"
import { KeyboardEvent, useEffect, useRef, useState } from "react"
import { IBanners } from "../../global/types"
import { api } from "../../services/api"

export function LayoutSite() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { cliente, logout } = useSiteContext()

  const refSearch = useRef<HTMLInputElement>(null)

  const [search, setSearch] = useState("")

  const [listBanner, setListBanner] = useState<IBanners[]>([])

  const handleLogout = () => {
    if (!window.confirm("Deseja realmente sair da sua conta?")) return

    logout()
  }

  const handleCloseMenu = () => {
    const divClose = document.getElementById("menuSideBar")

    if (!divClose) return

    divClose.style.opacity = "0"
    divClose.style.width = "0"
  }

  const handleOpenMenu = () => {
    const divClose = document.getElementById("menuSideBar")

    if (!divClose) return

    divClose.style.opacity = "1"
    divClose.style.width = "90%"
  }

  const handleSearchKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") navigate(`/eventos?q=${search}`)
  }

  const handleFocusSearch = () => {
    if (refSearch.current !== null) refSearch.current.select()
  }

  const loadBanners = async () => {
    await api.get("banners").then((res) => {
      if (!res.data) return

      setListBanner(res.data)
    })
  }

  const handleMenuSideBar = (to: string) => {
    handleCloseMenu()
    navigate(to)
  }

  useEffect(() => {
    if (pathname === "/") loadBanners()
  }, [pathname])

  return (
    <>
      <div id="menuSideBar">
        <h2>MENU</h2>
        <FontAwesomeIcon
          icon={faTimes}
          className="closeMenu"
          onClick={() => handleCloseMenu()}
        />

        <div onClick={() => handleMenuSideBar("/eventos")}>
          <FontAwesomeIcon icon={faBars} />
          TODOS OS EVENTOS
        </div>

        {/* <div>
          <FontAwesomeIcon icon={faCalendarDays} />
          EVENTOS POR DIA
        </div> */}

        <div onClick={() => handleMenuSideBar("/cidades")}>
          <FontAwesomeIcon icon={faLocationDot} />
          EVENTOS POR CIDADE
        </div>
      </div>

      {/* <div className="development">
        SITE EM DESENVOLVIMENTO. TODOS OS ITENS SÃO PARA FINS DE TESTES.
      </div> */}
      <div className="topoSite">
        <div className="sideBar">
          <FontAwesomeIcon icon={faBars} onClick={() => handleOpenMenu()} />
        </div>

        <div className="columnLeft">
          <div>
            <p>(16) 99646-2785</p>
            <p>SEGUNDA A SEXTA DAS 8H ÀS 17H</p>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faFacebook}
              onClick={() => {
                window.open("https://pt-br.facebook.com/")
              }}
            />
            <FontAwesomeIcon
              icon={faInstagram}
              onClick={() => {
                window.open("https://www.instagram.com/")
              }}
            />
          </div>
        </div>

        <div className="columnLogo">
          <img
            src={imgLogo}
            style={{ height: "150px" }}
            onClick={() => navigate("/")}
          />
        </div>

        <div className="columnRight">
          <div>
            {cliente?.nome && cliente.nome.length > 3 && (
              <>
                <p>Olá {cliente.nome}</p>|
                <p style={{ cursor: "pointer" }} onClick={() => handleLogout()}>
                  Sair
                </p>
              </>
            )}

            {!cliente?.nome && (
              <>
                <FontAwesomeIcon icon={faUser} color="var(--yellow-500)" />
                <Link to="cliente/login" state={{ goTo: "/meusIngressos" }}>
                  Login
                </Link>
                |
                <Link to="cliente/login" state={{ goTo: "/meusIngressos" }}>
                  Cadastrar
                </Link>
              </>
            )}
          </div>
          <div>
            {cliente?.nome && cliente.nome.length > 3 && (
              <Link to="/minhaConta">MINHA CONTA</Link>
              // <Link to="/meusIngressos">MEUS INGRESSOS</Link>
            )}
            |<Link to="/carrinho">CARRINHO</Link>
          </div>
        </div>
      </div>

      <div className="menuSite">
        <div onClick={() => navigate("/eventos")}>
          <FontAwesomeIcon icon={faBars} />
          TODOS OS EVENTOS
        </div>
        {/* <div>
          <FontAwesomeIcon icon={faCalendarDays} />
          EVENTOS POR DIA
        </div> */}
        <div onClick={() => navigate("/cidades")}>
          <FontAwesomeIcon icon={faLocationDot} />
          EVENTOS POR CIDADE
        </div>
        <div>
          <div className="search">
            <input
              value={search}
              ref={refSearch}
              onFocus={handleFocusSearch}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Procurar"
              onKeyUp={(e) => handleSearchKeyUp(e)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              onClick={() => navigate(`/eventos?q=${search}`)}
            />
          </div>
        </div>
      </div>

      {pathname === "/" && listBanner.filter((i) => i.visivel).length > 0 && (
        <div className="banners">
          <Carousel
            showThumbs={false}
            showArrows={false}
            interval={3000}
            showStatus={false}
            emulateTouch
            autoPlay
            infiniteLoop
            useKeyboardArrows
            autoFocus
            dynamicHeight
          >
            {listBanner
              .filter((i) => i.visivel)
              .map((item) => {
                const link = item.link ?? "#"
                return (
                  <a key={item.id} style={{ display: "block" }} href={link}>
                    <img
                      src={`${api.defaults.baseURL}/bannerImg/${item.id}.jpg`}
                      alt=""
                    />
                  </a>
                )
              })}
          </Carousel>
        </div>
      )}

      <div
        className="bodySite"
        style={{
          minHeight: "calc(100vh - 255px)",
          backgroundImage: `url(${bgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
        <div className="contentBody">
          <Outlet />
        </div>

        <p className="dev" style={{ fontSize: "14px", fontWeight: "bold" }}>
          Ticket Capital
        </p>
        <p className="dev" style={{ fontSize: "12px" }}>
          Av. das Bordadeiras, nº 151 - Jardim dos Bordados, Ibitinga - SP,
          14940-000
        </p>
        <p className="dev" style={{ fontSize: "12px" }}>
          Contato: (16) 9 9646-2785
        </p>
        <p
          className="dev"
          style={{ fontSize: "11px", cursor: "pointer" }}
          onClick={() =>
            window.open("https://www.datacontrolinformatica.com.br/")
          }
        >
          V. {PackageJson.version} - Desenvolvido por Data Control Informática
        </p>
      </div>

      <div
        className="divWhatsapp"
        onClick={() =>
          window.open(
            "https://web.whatsapp.com/send?phone=5516996462785&text=Oi!%20Estou%20entrando%20em%20contato%20pelo%20site%20ticketcapital.com.br.%20Poderia%20me%20ajudar?"
          )
        }
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </div>
    </>
  )
}
