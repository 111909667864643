type IUf = {
  cod: number;
  nome: string;
  uf: string;
}

export const listUf: IUf[] = [
  { cod: 11, nome: "Rondônia", uf: "RO" },
  { cod: 12, nome: "Acre", uf: "AC" },
  { cod: 13, nome: "Amazonas", uf: "AM" },
  { cod: 14, nome: "Roraima", uf: "RR" },
  { cod: 15, nome: "Pará", uf: "PA" },
  { cod: 16, nome: "Amapá", uf: "AP" },
  { cod: 17, nome: "Tocantins", uf: "TO" },
  { cod: 21, nome: "Maranhão", uf: "MA" },
  { cod: 22, nome: "Piauí", uf: "PI" },
  { cod: 23, nome: "Ceará", uf: "CE" },
  { cod: 24, nome: "Rio Grande do Norte", uf: "RN" },
  { cod: 25, nome: "Paraíba", uf: "PB" },
  { cod: 26, nome: "Pernambuco", uf: "PE" },
  { cod: 27, nome: "Alagoas", uf: "AL" },
  { cod: 28, nome: "Sergipe", uf: "SE" },
  { cod: 29, nome: "Bahia", uf: "BA" },
  { cod: 31, nome: "Minas Gerais", uf: "MG" },
  { cod: 32, nome: "Espírito Santo", uf: "ES" },
  { cod: 33, nome: "Rio de Janeiro", uf: "RJ" },
  { cod: 35, nome: "São Paulo", uf: "SP" },
  { cod: 41, nome: "Paraná", uf: "PR" },
  { cod: 42, nome: "Santa Catarina", uf: "SC" },
  { cod: 43, nome: "Rio Grande do Sul", uf: "RS" },
  { cod: 50, nome: "Mato Grosso do Sul", uf: "MS" },
  { cod: 51, nome: "Mato Grosso", uf: "MT" },
  { cod: 52, nome: "Goiás", uf: "GO" },
  { cod: 53, nome: "Distrito Federal", uf: "DF" },
];