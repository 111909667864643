import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";
import { MinhaContaLayout } from "../../components/MinhaContaLayout";
import { IEventosVendas } from "../../global/types";
import { useSiteContext } from "../../hooks/useSiteContext"
import { api } from "../../services/api";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { compareInDays, convertDateToBr } from "../../utils/dateProvider";
import { getTipoSetor } from "../../utils/getTipoSetor";

import "./style.css";

export function MeusIngressos() {
  const { cliente } = useSiteContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [list, setList] = useState<IEventosVendas[]>([]);

  const loadIngresos = async () => {
    if (!cliente || !cliente.id) return;

    setLoading(true);
    setTxtLoading("Carregando últimos ingressos comprados...");

    await api.post("eventos/vendas/cliente", { idCliente: cliente.id })
      .then((res) => {
        if (!res.data)
          return;

        setList(res.data.result);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          alert(err.response.data.message);
        else
          alert("Erro ao recuperar a lista de ingressos. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  const handleOpenIngresso = (item: IEventosVendas) => {
    if (item.status.includes("paid"))
      navigate(`/ingresso/${item.id}`);

    if (item.forma_pgto === "pix" && compareInDays(item.created_at, new Date()) === 0 && (item.status.includes("waiting_payment") || item.status.includes("pending")))
      navigate(`/waitingPayment/${item.order_id}`);
  }

  useEffect(() => {
    loadIngresos();
  }, [cliente]);

  return (
    <MinhaContaLayout>
      <div id="pageMeusIngressos">

        {loading && (
          <Load txtLoading={txtLoading} />
        )}

        {!loading && (
          <>
            <div className="sectionTitle">
              <p>
                MEUS INGRESSOS
              </p>
            </div>

            <div className="ingressosContent">

              {list.map(item => (
                <div className="cardIngresso" key={item.id} onClick={() => handleOpenIngresso(item)}>
                  <img src={`${api.defaults.baseURL}/eventoImg/${item.id_evento}.jpg`} />
                  <p>{item.evento?.nome}</p>
                  <span>
                    <strong>Setor: </strong> {item.lote?.setor?.nome}
                  </span>
                  <span>
                    ( {item.lote?.setor?.tipo ? getTipoSetor(item.lote?.setor?.tipo) : ""} )
                  </span>
                  <span>
                    <strong>Data do evento: </strong>
                  </span>
                  <span>
                    {item.evento?.data && convertDateToBr(item.evento?.data)}
                  </span>
                  <span>
                    <strong>Valor: </strong> {currencyFormatter(parseFloat(`${item.valor}`))}
                  </span>
                  <span>
                    <strong>Forma de Pgto:</strong><br />
                    {item.forma_pgto.toUpperCase() === "CREDIT_CARD" ? "Cartão de Crédito" : item.forma_pgto.toUpperCase()}
                  </span>

                  {item.entregue && (
                    <div className="used">UTILIZADO</div>
                  )}

                  {item.status.includes("paid") && !item.entregue && (
                    <div className="paid">PAGO</div>
                  )}

                  {item.status.includes("failed") && (
                    <div className="failed">NÃO PAGO</div>
                  )}

                  {(item.status.includes("waiting_payment") || item.status.includes("pending")) && (
                    <>
                      {item.forma_pgto === "pix" && compareInDays(item.created_at, new Date()) === 0 && (
                        <>
                          <div className="pending">AGUARDANDO PAGAMENTO</div>
                          <span style={{ border: "2px dashed #333", borderRadius: "7px", padding: "5px", width: "150px", margin: "5px 0px" }}>clique aqui e realize o pagamento</span>
                        </>
                      )}
                      {item.forma_pgto === "pix" && compareInDays(item.created_at, new Date()) > 0 && (
                        <div className="failed">PGTO NÃO REALIZADO</div>
                      )}
                      {item.forma_pgto !== "pix" && (
                        <div className="pending">AGUARDANDO PAGAMENTO</div>
                      )}
                    </>
                  )}

                </div>
              ))}

            </div>

          </>
        )}

      </div>
    </MinhaContaLayout>
  )
}