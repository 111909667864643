import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";
import { ICidades } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import "./style.css";

export function CidadesAdm() {
  const navigate = useNavigate();
  const { tokens, checkToken } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [list, setList] = useState<ICidades[]>([]);

  const loadList = async () => {
    setLoading(true);
    setTxtLoading("Carregando lista de Cidades...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    await api.get("cidades")
      .then((res) => {
        if (!res.data)
          return;

        setList(res.data.result);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          alert(err.response?.data?.message);
        else
          alert("Erro ao tentar recuperar a lista de cidades. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })

  }

  const handleDelCidade = async (cidade: ICidades) => {
    if (!window.confirm(`Deseja realmente excluir a cidade ${cidade.nome}?`))
      return;

    setLoading(true);
    setTxtLoading("Excluindo cidade...");

    try {
      const canLoad = checkToken();
      if (!canLoad)
        return;

      await api.delete("cidades", { data: { id: cidade.id } });
      loadList();
    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert(`Erro ao excluir ${cidade.nome}. Tente novamente mais tarde.`);

      setLoading(false);
      setTxtLoading("");
    }

  }

  useEffect(() => {
    if (tokens?.token) loadList();
  }, [tokens]);

  return (
    <div id="pageCidades">

      <button onClick={() => navigate("novo")} className="btnNovo btn-green">
        <FontAwesomeIcon icon={faCirclePlus} />
        NOVO
      </button>

      <div className="tableContext">
        <div className="headTable">
          <span style={{ width: "120px", justifyContent: "center" }}>Ações</span>
          <span style={{ flex: '1' }}>Nome</span>
        </div>

        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
        )}

        {!loading && (
          <div className="contentTable">

            {list.map(item => (
              <div className="rowTable" key={item.id}>
                <span>
                  <button className="btn-red" onClick={() => handleDelCidade(item)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>

                  <button className="btn-purple" onClick={() => navigate(item.id)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                </span>
                <span style={{ flex: '1' }}>{item.nome}/{item.estado}</span>
              </div>
            ))}
          </div>
        )}

      </div>

    </div>
  )
}