import { faEdit, faImage } from "@fortawesome/free-regular-svg-icons"
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCirclePlus,
  faSearch,
  faTicket,
  faTrash
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Input } from "../../components/Input"
import { Load } from "../../components/Load"
import {
  ICidades,
  IEventos,
  IFilterEventos,
  IUsuario
} from "../../global/types"
import { useAdmContext } from "../../hooks/useAdmContext"
import { api } from "../../services/api"
import { convertDateToBr, convertToDate } from "../../utils/dateProvider"
import Modal from "react-modal"

import "./style.css"
import { defaultModalStyle } from "../../utils/defaultModalStyle"
import { EventoSetores } from "../../modals/EventoSetores"
import { EventoImage } from "../../modals/EventoImage"
import { currencyFormatter } from "../../utils/currencyFormatter"
import { dynamicSort } from "../../utils/dynamicSort"

type IListEventos = {
  list: IEventos[]
  index: number
}

export function EventosAdm() {
  const navigate = useNavigate()
  const { tokens, checkToken, usuario } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [limitPage, setLimitPage] = useState(25)

  const [list, setList] = useState({} as IListEventos)

  const [listCidades, setListCidades] = useState<ICidades[]>([])

  const [cidadesPermissao, setCidadesPermissao] = useState<string[]>([])

  const [filterCidade, setFilterCidade] = useState("")
  const [filterNome, setFilterNome] = useState("")
  const [filterDataEvento, setFilterDataEvento] = useState("")

  const [modalImagemOpen, setModalImagemOpen] = useState(false)
  const [modalSetorOpen, setModalSetorOpen] = useState(false)
  const [eventoSelected, setEventoSelected] = useState<IEventos>({} as IEventos)

  const getCidadesUsuario = async () => {
    if (!usuario) return

    setLoading(true)
    setTxtLoading("Recuperando permissões do usuário")

    const canLoad = checkToken()
    if (!canLoad) return

    if (usuario.admin) loadCidades()
    else {
      try {
        const reqUser = await api.get(`users/${usuario.idUser}`)

        if (!reqUser.data)
          throw new Error("Erro ao carregar a lista de cidades do Usuário")

        const resUser = reqUser.data as IUsuario
        let newCidadesPermissao: string[] = []

        const newListCidades = resUser.cidades.map((item) => {
          newCidadesPermissao.push(item.id)
          return item.cidade
        })

        setCidadesPermissao(newCidadesPermissao)
        setListCidades(newListCidades)
      } catch (err: any) {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else alert("Erro ao carregar as permissões do Usuário")

        setLoading(false)
        setTxtLoading("")
      }
    }
  }

  const loadCidades = async () => {
    setLoading(true)
    setTxtLoading("Carregando lista de cidades")

    const canLoad = checkToken()
    if (!canLoad) return

    await api
      .get("cidades")
      .then((res) => {
        if (!res.data) return

        setListCidades(res.data.result)
      })
      .catch(() => {
        alert("Erro ao carregar lista de cidades.")
      })
  }

  const loadList = async () => {
    if (!usuario) return

    setLoading(true)
    setTxtLoading("Carregando lista de eventos...")

    let cidadeFilter: string[] | undefined = usuario.admin
      ? undefined
      : cidadesPermissao
    if (filterCidade.length > 0) cidadeFilter = [filterCidade]

    const filters: IFilterEventos = {
      cidades: cidadeFilter,
      nome: filterNome.length >= 3 ? filterNome : undefined,
      data:
        filterDataEvento.length === 10
          ? convertToDate(filterDataEvento)
          : undefined
    }

    await api
      .post(`eventos/list`, {
        pesquisa: { ...filters },
        cursor: limitPage * page - limitPage,
        limit: limitPage
      })
      .then((res) => {
        if (!res.data) return

        const newMaxPage = Math.ceil(res.data.total / limitPage)

        setList({
          index: 0,
          list: res.data.result
        })
        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage)
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else
          alert("Erro ao carregar lista de eventos. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }

  const handleDelete = async (evento: IEventos) => {
    if (!window.confirm(`Deseja realmente excluir o evento: ${evento.nome}?`))
      return

    setLoading(true)
    setTxtLoading("Excluindo evento")

    const canLoad = checkToken()
    if (!canLoad) return

    try {
      await api.delete("eventos", {
        data: { id: evento.id }
      })

      loadList()
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert("Erro ao excluir evento. Tente novamente mais tarde")

      setLoading(false)
      setTxtLoading("")
    }
  }

  const handleOpenModalSetor = (e: IEventos) => {
    setEventoSelected(e)
    setModalSetorOpen(true)
  }

  const handleOpenModalImagem = (e: IEventos) => {
    setEventoSelected(e)
    setModalImagemOpen(true)
  }

  const loadTotalVendas = async () => {
    const newList: IEventos[] = []

    await Promise.allSettled(
      list.list.sort(dynamicSort("-data")).map(async (item, i) => {
        if (i === list.index) {
          try {
            const reqTotal = await api.post("eventos/vendas/total", {
              idEvento: item.id
            })

            const total = reqTotal.data

            console.log(item.nome, total)

            newList.push({ ...item, totalVendas: parseFloat(total) })
          } catch (err: any) {
            newList.push(item)
          }
        } else {
          console.log(item.nome, "-")
          newList.push(item)
        }
      })
    )

    setList((s) => ({
      index: s.index + 1,
      list: newList.sort(dynamicSort("-data"))
    }))
  }

  useEffect(() => {
    if (tokens?.token) getCidadesUsuario()
  }, [tokens])

  useEffect(() => {
    if (list.list && list.list.length > 0 && list.index <= list.list.length)
      loadTotalVendas()
  }, [list])

  useEffect(() => {
    loadList()
  }, [page])

  return (
    <div id="pageEventosAdm">
      {loading && (
        <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
      )}

      {!loading && (
        <>
          <button
            onClick={() => navigate("novo")}
            className="btnNovo btn-green"
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            NOVO
          </button>

          <div className="tableContext">
            <p>Pesquisa</p>
            <hr />

            <div className="form">
              <span style={{ width: "49%" }}>
                <label>Nome</label>
                <Input
                  mask="none"
                  value={filterNome}
                  inputMaskChange={(v) => setFilterNome(v)}
                  placeholder="Pesquise o nome do evento"
                />
              </span>
              <span style={{ width: "49%" }}>
                <label>Data do Evento</label>
                <Input
                  mask="date"
                  value={filterDataEvento}
                  inputMaskChange={(v) => setFilterDataEvento(v)}
                  maxLength={10}
                />
              </span>
              <span style={{ width: "49%" }}>
                <label>Cidade</label>
                <select
                  value={filterCidade}
                  onChange={(e) => setFilterCidade(e.target.value)}
                >
                  <option value="">Todas</option>
                  {listCidades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.nome}/{item.estado}
                    </option>
                  ))}
                </select>
              </span>
            </div>

            <button
              className="btn-blue"
              style={{ margin: "15px auto 0px auto" }}
              onClick={() => loadList()}
            >
              <FontAwesomeIcon icon={faSearch} />
              Pesquisar
            </button>
          </div>

          <div className="tableContext">
            <div className="headTable">
              <span style={{ width: "225px" }}></span>
              <span style={{ flex: "2" }}>Nome</span>
              <span style={{ flex: "2" }}>Cidade</span>
              <span style={{ flex: "1", justifyContent: "center" }}>Data</span>
              <span style={{ flex: "1", justifyContent: "right" }}>
                Total Vendas
              </span>
            </div>

            <div className="contentTable">
              {list.list &&
                list.list.map((item) => (
                  <div className="rowTable" key={item.id}>
                    <span style={{ width: "210px", justifyContent: "center" }}>
                      <button
                        className="btn-red"
                        onClick={() => handleDelete(item)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      <button
                        className="btn-purple"
                        onClick={() => navigate(item.id)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        className="btn-blue"
                        onClick={() => handleOpenModalSetor(item)}
                      >
                        <FontAwesomeIcon icon={faTicket} />
                      </button>
                      <button
                        className="btn-green"
                        onClick={() => handleOpenModalImagem(item)}
                      >
                        <FontAwesomeIcon icon={faImage} />
                      </button>
                    </span>
                    <span style={{ flex: "2" }}>{item.nome}</span>
                    <span style={{ flex: "2" }}>
                      {item.cidade.nome}/{item.cidade.estado}
                    </span>
                    <span style={{ flex: "1", justifyContent: "center" }}>
                      {convertDateToBr(item.data)}
                    </span>
                    <span style={{ flex: "1", justifyContent: "right" }}>
                      {item.totalVendas
                        ? currencyFormatter(parseFloat(`${item.totalVendas}`))
                        : "-"}
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div className="pagination">
            {page !== 1 && (
              <FontAwesomeIcon
                icon={faAngleDoubleLeft}
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => setPage((i) => --i)}
              />
            )}
            <p>
              {page}/{maxPage}
            </p>
            {page !== maxPage && (
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => setPage((i) => ++i)}
              />
            )}
          </div>
        </>
      )}

      <Modal isOpen={modalSetorOpen} style={defaultModalStyle}>
        <EventoSetores
          closeModal={() => setModalSetorOpen(false)}
          idEvento={eventoSelected.id}
        />
      </Modal>

      <Modal
        isOpen={modalImagemOpen}
        style={{
          content: { ...defaultModalStyle.content, height: 280 },
          overlay: { ...defaultModalStyle.overlay }
        }}
      >
        <EventoImage
          closeModal={() => setModalImagemOpen(false)}
          evento={eventoSelected}
        />
      </Modal>
    </div>
  )
}
