import { KeyboardEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import bgLogin from "../../assets/bg_login.jpg";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import "./style.css";

export function LoginAdm() {
  const navigate = useNavigate();
  const { saveTokens } = useAdmContext();

  const [usuario, setUsuario] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleEnviar = async () => {

    if (!usuario || usuario?.length < 3)
      return alert("Usuário não informado");

    if (!senha || senha?.length < 3)
      return alert("Senha não informado");

    setLoading(true);

    try {
      const response = await api.post("login", {
        user: usuario,
        senha
      });

      if (!response.data)
        throw new Error("Erro ao receber dados do servidor");

      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      saveTokens(response.data.token, response.data.refresh_token, response.data.user);

      navigate("/adm");

    } catch (err: any) {
      setLoading(false);
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro eo efetuar login. Tente novamente mais tarde");
    }

  }

  const handleKeySenha = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleEnviar();
  }

  return (
    <div id="loginPage" style={{
      background: `url('${bgLogin}')`
    }}>
      <div className="form">
        {loading && (
          <Load txtLoading="Efetuando login..." options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <span>Usuário:</span>
            <Input
              mask="none"
              value={usuario}
              inputMaskChange={setUsuario}
              placeholder="Seu usuário"
              type="email"
            />

            <span>Senha:</span>
            <Input
              mask="none"
              value={senha}
              inputMaskChange={setSenha}
              placeholder="Sua senha"
              type="password"
              onKeyUp={handleKeySenha}
            />

            <button onClick={handleEnviar}>ENVIAR</button>
          </>
        )}

      </div>
    </div>
  );
}