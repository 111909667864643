import "./style.css"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { Load } from "../../components/Load"
import { IBanners } from "../../global/types"
import { useAdmContext } from "../../hooks/useAdmContext"
import { Input } from "../../components/Input"
import { api } from "../../services/api"

type IProps = {
  closeModal: () => void
  loadBanners: () => void
  banner?: IBanners
}

export function BannerImage({ closeModal, loadBanners, banner }: IProps) {
  const { checkToken } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [link, setLink] = useState("")

  const handleSubmit = async () => {
    setLoading(true)
    setTxtLoading("Enviando banner...")

    const canLoad = checkToken()
    if (!canLoad) return

    const formData = new FormData()
    formData.append("banners", selectedFile, selectedFile.name)
    formData.append("link", link)
    // formData.append("visivel", "true");

    if (banner) formData.append("id", banner.id)

    try {
      await api.post("banners", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })

      alert("Enviado com sucesso!")

      if (banner) window.location.reload()
      else {
        loadBanners()
        closeModal()
      }
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert("Erro ao enviar banner para o servidor")

      setLoading(false)
      setTxtLoading("")
    }
  }

  useEffect(() => {
    if (selectedFile !== null) handleSubmit()
  }, [selectedFile])

  return (
    <div id="modalBannerImage">
      <div className="title">
        <p>Banner</p>
        <FontAwesomeIcon
          icon={faTimes}
          style={{ cursor: "pointer" }}
          onClick={closeModal}
        />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <FileUploader
            handleChange={setSelectedFile}
            name="file"
            types={["JPG", "PNG"]}
            maxSize={2}
            onSizeError={() => {
              alert("Selecione uma imagem menor")
            }}
            hoverTitle="Solte aqui"
            label="Carregue ou solte um arquivo aqui"
            classes="dropFile"
          />
        )}

        <span style={{ width: "100%", marginTop: "8px", padding: "0 46px" }}>
          <label>Link do Banner</label>
          <Input mask="none" inputMaskChange={(v) => setLink((e) => v)} />
        </span>
      </div>
    </div>
  )
}
