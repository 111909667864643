import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IStorageAdm, ITokens, IUsuarioLogado } from "../global/types";
import { api } from "../services/api";

type AdmContextType = {
  tokens: ITokens | undefined;
  usuario: IUsuarioLogado | undefined;
  saveTokens: (token: string, refreshToken: string, user: IUsuarioLogado) => void;
  logout: () => void;
  checkToken: () => Promise<boolean>;
};

type ContextProps = {
  children: ReactNode;
}

export const AdmContext = createContext({} as AdmContextType);

export function AdmContextProvider(props: ContextProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [tokens, setTokens] = useState<ITokens>({} as ITokens);
  const [usuario, setUsuario] = useState<IUsuarioLogado>({} as IUsuarioLogado);

  const saveTokens = (token: string, refreshToken: string, user: IUsuarioLogado) => {
    setUsuario(user);
    setTokens({
      token,
      refreshToken
    });

    const lsStringify = JSON.stringify({
      tokens: {
        token,
        refreshToken,
      },
      user
    });

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem(`ticketCapitalAdm`, lsStringify);
  }

  const logout = () => {
    localStorage.removeItem("ticketCapitalAdm");
    setTokens({} as ITokens);
    setUsuario({} as IUsuarioLogado);
    navigate("/login");
  }

  const checkToken = async (): Promise<boolean> => {
    if (!pathname.includes("/adm")) return true;

    try {
      await api.post("verify-token");
      return true;
    } catch (err: any) {
      if (err.response.status === 401) {
        if (!tokens.refreshToken)
          navigate("/login");
        else {
          try {
            const resRefresh = await api.post("refresh-token", { token: tokens.refreshToken });
            api.defaults.headers.common['Authorization'] = `Bearer ${resRefresh.data.token}`;
            saveTokens(resRefresh.data.token, resRefresh.data.refreshToken, usuario);
          } catch (error) {
            navigate("/login");
          }
        }
      }
      return false;
    }
  }

  useEffect(() => {
    const storage = localStorage.getItem(`ticketCapitalAdm`);

    if (storage) {
      const lsParse = JSON.parse(storage) as IStorageAdm;

      api.defaults.headers.common['Authorization'] = `Bearer ${lsParse.tokens.token}`;
      setTokens(lsParse.tokens);
      setUsuario(lsParse.user);
    } else {
      if (pathname.includes("/adm"))
        navigate("/login");
    }

  }, []);

  useEffect(() => {
    if (tokens.token)
      checkToken();
  }, [tokens]);

  return (
    <AdmContext.Provider value={{
      tokens,
      usuario,
      saveTokens,
      logout,
      checkToken
    }}>
      {props.children}
    </AdmContext.Provider>
  )
}