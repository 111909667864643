import { faUser } from "@fortawesome/free-regular-svg-icons"
import {
  faAnglesRight,
  faAsterisk,
  faKey,
  faMobileScreen
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { KeyboardEvent, useState } from "react"
import { Load } from "../../components/Load"
import { IClientes, ICreateClientes } from "../../global/types"
import { api } from "../../services/api"
import { maskFirebasePhone, maskPhone } from "../../utils/masks"

import "../../services/firebase"
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber
} from "firebase/auth"

import "./style.css"
import { useSiteContext } from "../../hooks/useSiteContext"
import { useLocation, useNavigate } from "react-router-dom"

type IParams = {
  goTo?: string | null
}

export function LoginCliente() {
  const navigate = useNavigate()
  const { saveCliente } = useSiteContext()

  const { state } = useLocation()
  const params = state as IParams
  const goTo = params?.goTo ? params.goTo : undefined

  const auth = getAuth()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [telefone, setTelefone] = useState("")

  const [nome, setNome] = useState("")
  const [senha, setSenha] = useState("")

  const [verifyCode, setVerifyCode] = useState("")

  const [tempCliente, setTempCliente] = useState<IClientes>({} as IClientes)

  const [mostraDigPhone, setMostraDigPhone] = useState(true)
  const [mostraDigPass, setMostraDigPass] = useState(false)
  const [mostraPhoneCheck, setMostraPhoneCheck] = useState(false)
  const [mostraCadastro, setMostraCadastro] = useState(false)

  const handleEnviarSMS = () => {
    setLoading(true)
    setTxtLoading("Enviando SMS de verificação")

    window.recaptchaVerifier = new RecaptchaVerifier(
      "divRecaptchaVerifier",
      {
        size: "invisible",
        callback: (response: any) => {
          console.log("recaptchaVerifier", response)
        }
      },
      auth
    )

    signInWithPhoneNumber(
      auth,
      `+55 ${maskFirebasePhone(`${telefone.replace(/[^0-9]/g, "")}`)}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the user in with confirmationResult.confirm(code).

        window.confirmationResult = confirmationResult
        setMostraDigPhone(false)
        setMostraDigPass(false)
        setMostraPhoneCheck(true)
        setLoading(false)
        setTxtLoading("")
      })
      .catch((error) => {
        alert("Erro ao enviar SMS. Tente novamente mais tarde")
        console.log(error)

        setLoading(false)
        setTxtLoading("")
      })
  }

  const handleDigPhone = async () => {
    if (telefone.length !== 15)
      return alert("Digite um número de celular válido")

    setLoading(true)
    setTxtLoading("Verificando número de celular")

    try {
      const phoneNumber = telefone.replace(/[^0-9]/g, "")
      const reqCliente = await api.get(`clientes/phone/${phoneNumber}`)

      if (!reqCliente.data)
        throw new Error("Erro ao recuperar as informações do Servidor")

      setTempCliente(reqCliente.data)
      setNome(reqCliente.data.nome)
      setMostraDigPhone(false)
      setMostraDigPass(true)

      setLoading(false)
      setTxtLoading("")
    } catch (err: any) {
      if (err.response?.data?.message) {
        if (err.response.data.message === "Cliente não encontrado") {
          handleEnviarSMS()
        } else console.log(err.response?.data?.message)
      } else {
        alert("Erro ao verificar número de celular. Tente novamente mais tarde")
        console.log(err)

        setLoading(false)
        setTxtLoading("")
      }
    }
  }

  const handleVerifyCode = async () => {
    setLoading(true)
    setTxtLoading("Verificando...")

    window.confirmationResult
      .confirm(verifyCode)
      .then((result: any) => {
        console.log(result)

        setMostraPhoneCheck(false)
        setMostraCadastro(true)
      })
      .catch((err: any) => {
        alert("Código incorreto.")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }

  const handleSalvarCliente = async () => {
    if (nome.length < 3) return alert("Digite um nome válido")

    if (senha.length < 6) return alert("Digite uma senha válida")

    setLoading(true)
    setTxtLoading("Salvando dados...")

    let newCliente: ICreateClientes = {
      nome,
      senha,
      telefone: parseInt(telefone.replace(/[^0-9]/g, ""))
    }

    if (tempCliente.id && tempCliente.id.length > 5)
      newCliente = { ...newCliente, id: tempCliente.id }

    try {
      const reqCad = await api.post("clientes", { ...newCliente })

      if (!reqCad.data) throw new Error("Erro ao recuperar dados do Servidor")

      saveCliente({
        id: reqCad.data.id,
        nome: reqCad.data.nome,
        telefone: reqCad.data.telefone
      })

      setLoading(false)
      setTxtLoading("")
      navigate(goTo && goTo !== null ? goTo : "/carrinho")
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert("Erro ao salvar dados do Cliente. Tente novamente mais tarde")

      setLoading(false)
      setTxtLoading("")
    }
  }

  const handleLogin = async () => {
    if (senha.length < 6) return alert("Digite uma senha válida")

    setLoading(true)
    setTxtLoading("Efetuando login...")

    try {
      const reqLogin = await api.post("clientes/login", {
        telefone: telefone.replace(/[^0-9]/g, ""),
        senha
      })

      if (!reqLogin.data) throw new Error("Erro ao recuperar dados do Servidor")

      saveCliente({
        id: reqLogin.data.id,
        nome: reqLogin.data.nome,
        telefone: parseInt(telefone.replace(/[^0-9]/g, ""))
      })
      navigate(goTo && goTo !== null ? goTo : "/carrinho")
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert("Erro efetuar o Login. Tente novamente mais tarde")

      setLoading(false)
      setTxtLoading("")
    }
  }

  const handleKeyUpPass = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleLogin()
  }

  const handleKeyUpPhone = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleDigPhone()
  }

  const handleKeyUpCheckPhone = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleVerifyCode()
  }

  const handleKeyUpCad = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSalvarCliente()
  }

  return (
    <div id="pageLoginCliente">
      <div id="divRecaptchaVerifier" />
      {loading && (
        <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
      )}

      {!loading && (
        <>
          {mostraDigPhone && (
            <div className="sectionPhone">
              <FontAwesomeIcon icon={faUser} className="iconLogin" />
              <h2>Login</h2>

              <div className="inputLogin">
                <FontAwesomeIcon icon={faMobileScreen} />
                <input
                  value={telefone}
                  onChange={(e) => setTelefone(maskPhone(e.target.value))}
                  placeholder="(00) 00000-0000"
                  maxLength={15}
                  onKeyUp={(e) => handleKeyUpPhone(e)}
                  type="tel"
                />
              </div>

              <button onClick={() => handleDigPhone()}>
                <FontAwesomeIcon icon={faAnglesRight} />
                Continuar
              </button>
            </div>
          )}

          {mostraPhoneCheck && (
            <div className="sectionPhone">
              <FontAwesomeIcon icon={faUser} className="iconLogin" />
              <h2>Verificação de Celular</h2>

              <p style={{ textAlign: "center", marginBottom: "15px" }}>
                Digite o código que enviamos <br />
                para o celular.
              </p>

              <div className="inputLogin">
                <FontAwesomeIcon icon={faKey} />
                <input
                  value={verifyCode}
                  onChange={(e) => setVerifyCode(e.target.value)}
                  placeholder="Digite o código de verificação"
                  onKeyUp={(e) => handleKeyUpCheckPhone(e)}
                />
              </div>

              <button onClick={() => handleVerifyCode()}>
                <FontAwesomeIcon icon={faAnglesRight} />
                Continuar
              </button>
            </div>
          )}

          {mostraCadastro && (
            <div className="sectionPhone">
              <FontAwesomeIcon icon={faUser} className="iconLogin" />
              <h2>Cadastro</h2>

              <div className="inputLogin">
                <FontAwesomeIcon icon={faUser} />
                <input
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  placeholder="Digite seu Nome"
                  onKeyUp={(e) => handleKeyUpCad(e)}
                />
              </div>

              <div className="inputLogin" style={{ marginTop: "15px" }}>
                <FontAwesomeIcon icon={faAsterisk} />
                <input
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  placeholder="Digite sua Senha"
                  type="password"
                  onKeyUp={(e) => handleKeyUpCad(e)}
                />
              </div>

              <button onClick={() => handleSalvarCliente()}>
                <FontAwesomeIcon icon={faAnglesRight} />
                Salvar
              </button>
            </div>
          )}

          {mostraDigPass && (
            <div className="sectionPhone">
              <FontAwesomeIcon icon={faUser} className="iconLogin" />
              <h2>Informe sua Senha</h2>

              <div className="inputLogin" style={{ marginTop: "15px" }}>
                <FontAwesomeIcon icon={faAsterisk} />
                <input
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  placeholder="Digite sua Senha"
                  type="password"
                  onKeyUp={(e) => handleKeyUpPass(e)}
                />
              </div>
              <p className="pLink" onClick={() => handleEnviarSMS()}>
                esqueci minha senha
              </p>

              <button onClick={() => handleLogin()}>
                <FontAwesomeIcon icon={faAnglesRight} />
                Continuar
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
