import { useEffect, useState } from "react"
import { Load } from "../../components/Load"
import { IClientes } from "../../global/types"
import { useAdmContext } from "../../hooks/useAdmContext"
import { api } from "../../services/api"
import { maskPhone } from "../../utils/masks"

import "./style.css"

export function ClientesAdm() {
  const { tokens, checkToken } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [list, setList] = useState<IClientes[]>([])

  const loadList = async () => {
    setLoading(true)
    setTxtLoading("Carregando lista de Clientes...")

    const canLoad = checkToken()
    if (!canLoad) return

    await api
      .get("clientes")
      .then((res) => {
        if (!res.data) return

        setList(res.data.result)
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else
          alert(
            "Erro ao tentar recuperar a lista de clientes. Tente novamente mais tarde."
          )
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }

  useEffect(() => {
    if (tokens?.token) loadList()
  }, [tokens])

  return (
    <div id="pageClientesAdm">
      <div className="tableContext">
        <div className="headTable">
          <span style={{ flex: "1" }}>Nome</span>
          <span style={{ flex: "1" }}>Telefone</span>
        </div>

        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
        )}

        {!loading && (
          <div className="contentTable">
            {list.map((item) => (
              <div className="rowTable" key={item.id}>
                <span style={{ flex: "1" }}>{item.nome}</span>
                <span style={{ flex: "1" }}>{maskPhone(item.telefone)}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
