import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { Load } from "../../components/Load"
import { IEventos } from "../../global/types"
import { useAdmContext } from "../../hooks/useAdmContext"
import { api } from "../../services/api"

import "./style.css"

type IProps = {
  closeModal: () => void
  evento: IEventos
}

export function EventoImage({ closeModal, evento }: IProps) {
  const { checkToken } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [selectedFile, setSelectedFile] = useState<any>(null)

  const handleSubmit = async () => {
    setLoading(true)
    setTxtLoading("Enviando imagem...")

    const canLoad = checkToken()
    if (!canLoad) return

    const formData = new FormData()
    formData.append("images", selectedFile, selectedFile.name)
    formData.append("eventoId", evento.id)

    try {
      await api.patch("eventos/image", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })

      alert("Enviado com sucesso!")
      closeModal()
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert("Erro ao enviar imagem para o servidor")

      setLoading(false)
      setTxtLoading("")
    }
  }

  useEffect(() => {
    if (selectedFile !== null) handleSubmit()
  }, [selectedFile])

  return (
    <div id="modalEventoImage">
      <div className="title">
        <p>Imagem: {evento.nome}</p>
        <FontAwesomeIcon
          icon={faTimes}
          style={{ cursor: "pointer" }}
          onClick={closeModal}
        />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <FileUploader
            handleChange={setSelectedFile}
            name="file"
            types={["JPG", "PNG"]}
            maxSize={1}
            onSizeError={() => {
              alert("Selecione uma imagem menor")
            }}
            hoverTitle="Solte aqui"
            label="Carregue ou solte um arquivo aqui"
            classes="dropFile"
          />
        )}
      </div>
    </div>
  )
}
