import axios from "axios"
import { Buffer } from "buffer"

const REACT_APP_DEV = process.env.REACT_APP_DEV === "production"

let auth = "sk_test_jKdnWEvinTLJoO6m:"
if (REACT_APP_DEV) auth = "sk_3c04fce75fff4280a52cab30c6d30a97:"

export const apiPagarMe = axios.create({
  baseURL: "https://cors.dcisuporte.com.br/api.pagar.me:443/core/v5",
  headers: {
    Authorization: `Basic ${Buffer.from(auth).toString("base64")}`,
    Accept: "*/*"
  }
})
