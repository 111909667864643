import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"

import { api } from "../../services/api"
import {
  convertDateToBr,
  convertToDiaMes,
  dateNow,
  getHourMin
} from "../../utils/dateProvider"

import img1 from "../../assets/e1.jpg"
import img2 from "../../assets/p1.jpg"
import { Load } from "../../components/Load"
import { IEventos } from "../../global/types"
import "./style.css"
import { useNavigate } from "react-router-dom"
import { dynamicSort } from "../../utils/dynamicSort"

export function Home() {
  const navigate = useNavigate()

  const [loadingEventos, setLoadingEventos] = useState(false)
  const [loadingProxEventos, setLoadingProxEventos] = useState(false)

  const [listEventos, setListEventos] = useState<IEventos[]>([])
  const [listProxEventos, setListProxEventos] = useState<IEventos[]>([])

  const loadEventos = async () => {
    setLoadingEventos(true)

    await api
      .post("eventos/list", {
        pesquisa: {
          inicioVendasAte: dateNow(),
          dataEventoDepois: dateNow()
        }
      })
      .then((res) => {
        if (!res.data) return

        setListEventos(res.data.result)
      })
      .catch((err) => {
        if (err.response?.data?.message)
          console.log(err.response?.data?.message)

        alert("Erro ao carregar lista de Eventos. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoadingEventos(false)
      })
  }

  const loadProxEventos = async () => {
    setLoadingProxEventos(true)

    await api
      .post("eventos/list", {
        pesquisa: { inicioVendasDepois: dateNow() }
      })
      .then((res) => {
        if (!res.data) return

        setListProxEventos(res.data.result)
      })
      .catch((err) => {
        if (err.response?.data?.message)
          console.log(err.response?.data?.message)

        alert(
          "Erro ao carregar lista de Próximos Eventos. Tente novamente mais tarde"
        )
      })
      .finally(() => {
        setLoadingProxEventos(false)
      })
  }

  useEffect(() => {
    loadEventos()
    loadProxEventos()
  }, [])

  return (
    <div id="pageHome">
      <div className="eventos">
        {loadingEventos && (
          <Load txtLoading="" options={{ height: 150, width: 150 }} />
        )}

        {!loadingEventos &&
          listEventos.sort(dynamicSort("data")).map((item) => (
            <div
              className="cardEvento"
              key={`e-${item.id}`}
              onClick={() => navigate(`/evento/${item.id}`)}
            >
              <div
                className="poster"
                style={{
                  backgroundImage: `url(${api.defaults.baseURL}/eventoImg/${item.id}.jpg)`
                }}
              >
                <p>{convertToDiaMes(item.data)}</p>
              </div>
              <div className="info">
                <div>
                  <p className="title">{item.nome.toUpperCase()}</p>
                  <p className="local">
                    {item.local.toUpperCase()} -{" "}
                    {item.cidade.nome.toUpperCase()}/
                    {item.cidade.estado.toUpperCase()}
                  </p>
                  <p className="hora"> {convertDateToBr(item.data)}</p>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCaretRight} />
                  <p>VER MAIS</p>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="sectionTitle">
        <p>
          PRÓXIMOS <span>EVENTOS</span>
        </p>
      </div>

      <div className="proxEventos">
        {loadingProxEventos && (
          <Load txtLoading="" options={{ height: 150, width: 150 }} />
        )}

        {!loadingProxEventos &&
          listProxEventos.map((item) => (
            <div
              className="cardProxEvento"
              key={`p-${item.id}`}
              onClick={() => navigate(`/evento/${item.id}`)}
            >
              <div
                className="poster"
                style={{
                  backgroundImage: `url(${api.defaults.baseURL}/eventoImg/${item.id}.jpg)`
                }}
              />
              <div className="info">
                <p>{item.nome}</p>
                <p>Início das vendas: {convertDateToBr(item.inicio_vendas)}h</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
