import './style.css'

import { api } from '../../services/api'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faDownload,
  faSearch,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'

import { useAdmContext } from '../../hooks/useAdmContext'
import { Input } from '../../components/Input'
import { Load } from '../../components/Load'

import { convertDateToBr } from '../../utils/dateProvider'

import { defaultModalStyle } from '../../utils/defaultModalStyle'

import { urlParams } from '../../utils/urlParams'
import { SorteioView } from '../../modals/SorteioView'
import { ISorteio } from '../../global/types'

type IListSorteio = {
  list: ISorteio[]
  index: number
}

export default function SorteioChuteiraAdm() {
  const { tokens, checkToken, usuario } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState('')

  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [limitPage] = useState(25)

  const [data, setData] = useState<IListSorteio>({} as IListSorteio)

  const [filters, setFilters] = useState({} as Partial<ISorteio>)

  const [modalImagemOpen, setModalImagemOpen] = useState(false)
  const [itemSelected, setItemSelected] = useState<ISorteio>({} as ISorteio)

  const loadList = async () => {
    if (!usuario) return

    setLoading(true)
    setTxtLoading('Carregando lista de sorteios...')

    await api
      .get(
        `sorteios?${urlParams(filters)}&limit=${limitPage}&cursor=${Number(
          page - 1
        )}`
      )
      .then((res) => {
        if (!res.data) return

        const newMaxPage = Math.ceil(res.data.total / limitPage)
        setData({
          index: 0,
          list: res.data.list
        })
        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage)
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else
          alert('Erro ao carregar lista de eventos. Tente novamente mais tarde')
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading('')
      })
  }

  const handleDelete = async (item: ISorteio) => {
    if (!window.confirm(`Deseja realmente excluir: ${item.nome_completo}?`))
      return

    setLoading(true)
    setTxtLoading('Excluindo sorteio')

    const canLoad = checkToken()
    if (!canLoad) return

    try {
      await api.delete(`sorteios/${item.id}`)
      loadList()
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert('Erro ao excluir evento. Tente novamente mais tarde')

      setLoading(false)
      setTxtLoading('')
    }
  }

  const handleDownloadExcel = async () => {
    await api
      .get(`sorteios/exportar`)
      .then(async (data) => window.open(`${data.data?.path}`))
  }

  // const handleOpenModalSetor = (e: IRifas) => {
  //   setItemSelected(e)
  //   setModalSetorOpen(true)
  // }

  const handleOpenModal = (e: ISorteio) => {
    setItemSelected(e)
    setModalImagemOpen(true)
  }

  useEffect(() => {
    loadList()
  }, [page])

  return (
    <div id="pageSorteioAdm">
      {loading && (
        <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
      )}

      {!loading && (
        <>
          <div className="tableContext">
            <p>Pesquisa</p>
            <hr />
            <div className="form">
              <span style={{ width: '30%' }}>
                <label>Nome</label>
                <Input
                  mask="none"
                  value={filters.nome_completo}
                  inputMaskChange={(v) =>
                    setFilters({ ...filters, nome_completo: v })
                  }
                  placeholder="Pesquise o nome do evento"
                />
              </span>

              <span style={{ width: '20%' }}>
                <label>Telefone</label>
                <Input
                  mask="phone"
                  value={filters.telefone}
                  inputMaskChange={(v) =>
                    setFilters({ ...filters, telefone: v })
                  }
                  placeholder="Pesquise pelo telefone"
                />
              </span>

              <span style={{ width: '20%' }}>
                <label>Documento</label>
                <Input
                  mask="none"
                  value={filters.documento}
                  inputMaskChange={(v) =>
                    setFilters({ ...filters, documento: v })
                  }
                  placeholder="Pesquise CPF/RG"
                />
              </span>
              <button className="btn-blue" onClick={() => loadList()}>
                <FontAwesomeIcon icon={faSearch} />
                Pesquisar
              </button>
              <button onClick={handleDownloadExcel} className="btn-blue">
                <FontAwesomeIcon icon={faDownload} />
                DOWNLOAD
              </button>
            </div>
          </div>

          <div className="tableContext">
            <div className="headTable">
              <span style={{ width: '235px' }}></span>
              <span style={{ flex: '2' }}>Nome</span>
              <span style={{ flex: '2' }}>Documento</span>
              <span style={{ flex: '1', justifyContent: 'left' }}>
                Telefone
              </span>
              <span style={{ flex: '1', justifyContent: 'right' }}>
                Dt.Cadastro
              </span>
            </div>

            <div className="contentTable">
              {data.list &&
                data.list.map((item: any) => (
                  <div className="rowTable" key={item.id}>
                    <span style={{ width: '235px', justifyContent: 'center' }}>
                      <button
                        className="btn-red"
                        onClick={() => handleDelete(item)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      <button
                        className="btn-purple"
                        onClick={() => handleOpenModal(item)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </span>
                    <span style={{ flex: '2' }}>{item.nome_completo}</span>
                    <span style={{ flex: '2' }}>{item.documento}</span>
                    <span style={{ flex: '1', justifyContent: 'left' }}>
                      {item.telefone}
                    </span>
                    <span style={{ flex: '1', justifyContent: 'right' }}>
                      {convertDateToBr(item.created_at)}
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div className="pagination">
            {page !== 1 && (
              <FontAwesomeIcon
                icon={faAngleDoubleLeft}
                style={{ fontSize: '15px', cursor: 'pointer' }}
                onClick={() => setPage((i) => --i)}
              />
            )}
            <p>
              {page}/{maxPage}
            </p>
            {page !== maxPage && (
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ fontSize: '15px', cursor: 'pointer' }}
                onClick={() => setPage((i) => ++i)}
              />
            )}
          </div>
        </>
      )}

      <Modal
        isOpen={modalImagemOpen}
        style={{
          content: { ...defaultModalStyle.content, height: 532, width: 800 },
          overlay: { ...defaultModalStyle.overlay }
        }}
      >
        <SorteioView
          closeModal={() => setModalImagemOpen(false)}
          item={itemSelected}
        />
      </Modal>
    </div>
  )
}
