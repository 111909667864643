import { createContext, ReactNode, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  IClienteLogado,
  ICreateEventosVendas,
  ICreateRifasVendas
} from "../global/types"
import { io, Socket } from "socket.io-client"
import { api } from "../services/api"

export type ICarrinho = ICreateEventosVendas & ICreateRifasVendas

type SiteContextType = {
  cliente: IClienteLogado | undefined
  carrinho: ICarrinho[] | undefined
  saveCliente: (cliente: IClienteLogado) => void
  saveCarrinho: (carrinho: ICarrinho[]) => void
  logout: () => void
  socket: Socket
  contextLoaded: boolean
}

type IStorage = {
  cliente: IClienteLogado
  carrinho: ICarrinho[]
}

type ContextProps = {
  children: ReactNode
}

export const SiteContext = createContext({} as SiteContextType)

export function SiteContextProvider(props: ContextProps) {
  const navigate = useNavigate()

  const [contextLoaded, setContextLoaded] = useState(false)

  const [cliente, setCliente] = useState<IClienteLogado>({} as IClienteLogado)
  const [carrinho, setCarrinho] = useState<ICarrinho[]>([])

  const socket = io(`${api.defaults.baseURL}`)

  const saveCliente = (c: IClienteLogado) => {
    setCliente(c)

    const lsStringify = JSON.stringify({
      cliente: {
        nome: c.nome,
        id: c.id,
        telefone: c.telefone
      },
      carrinho
    })

    localStorage.setItem(`ticketCapital`, lsStringify)
  }

  const saveCarrinho = (c: ICarrinho[]) => {
    setCarrinho(c)

    const lsStringify = JSON.stringify({
      cliente,
      carrinho: c
    })

    localStorage.setItem(`ticketCapital`, lsStringify)
  }

  const logout = () => {
    localStorage.removeItem(`ticketCapital`)
    setCliente({} as IClienteLogado)

    navigate("/")
  }

  useEffect(() => {
    const storage = localStorage.getItem(`ticketCapital`)

    if (storage) {
      const lsParse = JSON.parse(storage) as IStorage

      setCliente(lsParse.cliente)
      setCarrinho(lsParse.carrinho)
    }

    setContextLoaded(true)
  }, [])

  return (
    <SiteContext.Provider
      value={{
        cliente,
        carrinho,
        saveCliente,
        saveCarrinho,
        logout,
        socket,
        contextLoaded
      }}
    >
      {props.children}
    </SiteContext.Provider>
  )
}
