import { api } from "../../services/api"
import { faSave } from "@fortawesome/free-regular-svg-icons"
import { faUndo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Input } from "../../components/Input"
import { Load } from "../../components/Load"
import { ICidades, ICreateEvento, IEventos, IUsuario } from "../../global/types"
import { useAdmContext } from "../../hooks/useAdmContext"
import { convertDateToBr, convertToDate } from "../../utils/dateProvider"

import "./style.css"

type IProps = {
  tipoForm: "Edit" | "New"
}

type IParams = {
  idEvento: string
}

export function EventosFormAdm({ tipoForm }: IProps) {
  const navigate = useNavigate()
  const { idEvento } = useParams() as IParams
  const { tokens, checkToken, usuario } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [cadastro, setCadastro] = useState<ICreateEvento>({} as ICreateEvento)

  const [listCidades, setListCidades] = useState<ICidades[]>([])

  const loadCidades = async () => {
    setLoading(true)
    setTxtLoading("Carregando lista de cidades")

    await api
      .get("cidades")
      .then((res) => {
        if (!res.data) return

        setListCidades(res.data.result)
      })
      .catch(() => {
        alert("Erro ao carregar lista de cidades.")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }

  const getCidadesUsuario = async () => {
    if (!usuario) return

    setLoading(true)
    setTxtLoading("Recuperando permissões do usuário")

    const canLoad = checkToken()
    if (!canLoad) return

    if (usuario.admin) loadCidades()
    else {
      try {
        const reqUser = await api.get(`users/${usuario.idUser}`)

        if (!reqUser.data)
          throw new Error("Erro ao carregar a lista de cidades do Usuário")

        const resUser = reqUser.data as IUsuario
        const newListCidades = resUser.cidades.map((item) => {
          return item.cidade
        })

        setListCidades(newListCidades)
      } catch (err: any) {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else alert("Erro ao carregar as permissões do Usuário")

        setLoading(false)
        setTxtLoading("")
      }
    }
  }

  const loadCadastro = async () => {
    setLoading(true)
    setTxtLoading("Carregando evento...")

    const canLoad = checkToken()
    if (!canLoad) return

    await api
      .get(`eventos/${idEvento}`)
      .then((res) => {
        if (!res.data) return

        const cad = res.data as IEventos

        setCadastro({
          id: cad.id,
          nome: cad.nome,
          data: convertDateToBr(cad.data),
          endereco: cad.endereco,
          id_cidade: cad.id_cidade,
          inicio_vendas: convertDateToBr(cad.inicio_vendas),
          descricao: cad.descricao,
          local: cad.local,
          obs: cad.obs
        })
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else
          alert(
            "Erro ao tentar recuperar o cadastro. Tente novamente mais tarde."
          )
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })

    setLoading(false)
    setTxtLoading("")
  }

  const handleSave = async () => {
    if (cadastro?.nome?.length < 3)
      return alert("Preencha o nome do evento corretamente")

    if (cadastro?.id_cidade?.length < 5)
      return alert("Preencha a cidade do evento")

    if (cadastro?.local?.length < 3)
      return alert("Preencha o local do evento corretamente")

    if (cadastro?.endereco?.length < 3)
      return alert("Preencha o endereço do evento corretamente")

    if (cadastro?.data?.length !== 10)
      return alert("Preencha a data do evento corretamente")

    if (cadastro?.inicio_vendas?.length !== 10)
      return alert(
        "Preencha a data de início das vendas do evento corretamente"
      )

    if (cadastro?.inicio_vendas?.length < 10)
      return alert("Preencha a descrição do evento corretamente")

    setLoading(true)
    setTxtLoading("Salvando evento...")

    const canLoad = checkToken()
    if (!canLoad) return

    try {
      await api.post("eventos", {
        ...cadastro,
        data: convertToDate(cadastro.data),
        inicio_vendas: convertToDate(cadastro.inicio_vendas)
      })

      navigate(-1)
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert("Erro ao salvar evento. Tente novamente mais tarde.")

      setLoading(false)
      setTxtLoading("")
    }
  }

  useEffect(() => {
    if (tokens?.token) getCidadesUsuario()
  }, [tokens])

  useEffect(() => {
    if (listCidades.length > 0 && tipoForm === "Edit") loadCadastro()
  }, [listCidades, tipoForm])

  return (
    <div id="pageEventosForm">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && usuario?.admin && (
        <>
          <div className="body">
            <span style={{ width: "49%" }}>
              <label>Nome</label>
              <Input
                mask="none"
                value={cadastro.nome}
                inputMaskChange={(v) => setCadastro((e) => ({ ...e, nome: v }))}
              />
            </span>

            <span style={{ width: "49%" }}>
              <label>Cidade</label>
              <select
                value={cadastro.id_cidade}
                onChange={(v) =>
                  setCadastro((e) => ({ ...e, id_cidade: v.target.value }))
                }
              >
                <option value="">Selecione a Cidade</option>
                {listCidades.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nome}/{item.estado}
                  </option>
                ))}
              </select>
            </span>

            <span style={{ width: "49%" }}>
              <label>Local</label>
              <Input
                mask="none"
                value={cadastro.local}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, local: v }))
                }
              />
            </span>

            <span style={{ width: "49%" }}>
              <label>Endereço</label>
              <Input
                mask="none"
                value={cadastro.endereco}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, endereco: v }))
                }
              />
            </span>

            <span style={{ width: "24%" }}>
              <label>Data do Evento</label>
              <Input
                mask="date"
                value={cadastro.data}
                inputMaskChange={(v) => setCadastro((e) => ({ ...e, data: v }))}
                maxLength={10}
              />
            </span>

            <span style={{ width: "24%" }}>
              <label>Início das vendas</label>
              <Input
                mask="date"
                value={cadastro.inicio_vendas}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, inicio_vendas: v }))
                }
                maxLength={10}
              />
            </span>

            <span style={{ width: "49%" }}>
              <label>Observações</label>
              <Input
                mask="none"
                value={cadastro.obs}
                inputMaskChange={(v) => setCadastro((e) => ({ ...e, obs: v }))}
              />
            </span>

            <span style={{ width: "98.5%" }}>
              <label>Descrição</label>
              <textarea
                value={cadastro.descricao}
                onChange={(v) =>
                  setCadastro((e) => ({ ...e, descricao: v.target.value }))
                }
              />
            </span>
          </div>

          <div className="btnContainer">
            <button className="btn-green" onClick={() => handleSave()}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btn-purple" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </div>
        </>
      )}
    </div>
  )
}
