import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faBan, faCirclePlus, faListCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Load } from "../../components/Load";
import { IUsuario } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import Modal from "react-modal";
import { defaultModalStyle } from "../../utils/defaultModalStyle";

import "./style.css"
import { CidadesUsuario } from "../../modals/CidadesUsuario";

export function UsuariosAdm() {
  const navigate = useNavigate();
  const { tokens, usuario, checkToken } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [list, setList] = useState<IUsuario[]>([]);

  const [openModal, setOpenModal] = useState(false);
  const [idUserSelected, setIdUserSelected] = useState("");

  const loadList = async () => {
    setLoading(true);
    setTxtLoading("Carregando lista de usuários...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    await api.get("users/list")
      .then((res) => {
        if (!res.data)
          return;

        setList(res.data.result);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          alert(err.response?.data?.message);
        else
          alert("Erro ao tentar recuperar a lista de usuários. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleOpenModal = (id: string) => {
    setIdUserSelected(id);
    setOpenModal(true);
  }

  useEffect(() => {
    if (tokens?.token)
      loadList();

  }, [tokens]);

  return (
    <div id="pageUsuarios">

      {!usuario?.admin && (
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <FontAwesomeIcon icon={faBan} style={{ fontSize: "70px", color: "#ff5349" }} />
          <h2 style={{ marginTop: "15px" }}>USUÁRIO SEM PERMISSÃO</h2>
        </div>
      )}

      {usuario?.admin && (
        <>

          <button onClick={() => navigate("novo")} className="btnNovo btn-green">
            <FontAwesomeIcon icon={faCirclePlus} />
            NOVO
          </button>

          <div className="tableContext">
            <div className="headTable">
              <span style={{ width: "120px" }}></span>
              <span style={{ flex: '2' }}>Nome</span>
              <span style={{ flex: '2' }}>Usuário</span>
              <span style={{ flex: '1', justifyContent: "center" }}>Admin?</span>
            </div>


            {loading && (
              <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
            )}

            {!loading && (
              <div className="contentTable">
                {list && list.map(item => (
                  <div className="rowTable" key={item.id}>
                    <span style={{ width: "110px", justifyContent: "center" }}>
                      <button className="btn-blue" onClick={() => handleOpenModal(item.id)}>
                        <FontAwesomeIcon icon={faListCheck} />
                      </button>
                      <button className="btn-purple" onClick={() => navigate(item.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </span>
                    <span style={{ flex: '2' }}>{item.nome}</span>
                    <span style={{ flex: '2' }}>{item.user}</span>
                    <span style={{ flex: '1', justifyContent: "center" }}>{item.admin ? "Sim" : "Não"}</span>
                  </div>
                ))}
              </div>
            )}

          </div>
        </>
      )}

      <Modal isOpen={openModal} style={defaultModalStyle}>
        <CidadesUsuario closeModal={() => setOpenModal(false)} idUser={idUserSelected} />
      </Modal>

    </div>
  )
}