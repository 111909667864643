import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { IConfigSocioTorcedor, ISocio } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import { apiPagarMe } from "../../services/apiPagarMe";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { convertDateToBr, dateNow } from "../../utils/dateProvider";
import { dynamicSort } from "../../utils/dynamicSort";
import { maskPhone } from "../../utils/masks";
import "./style.css";

export function SocioTorcedorAdm() {
  const { tokens, checkToken, usuario } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [configSocio, setConfigSocio] = useState<IConfigSocioTorcedor | undefined>();

  const [listSocios, setListSocios] = useState<ISocio[]>([]);
  const [valorAssinatura, setValorAssinatura] = useState("");
  const [termo, setTermo] = useState("");

  const loadConfigSocio = async () => {
    setLoading(true);
    setTxtLoading("Carregando configurações...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    await api.get("configSocioTorcedor")
      .then(res => {
        if (!res.data)
          throw new Error("Servidor indisponível. Tente novamente mais tarde");

        setConfigSocio(res.data);
        setTermo(res.data.termo);
        setValorAssinatura(currencyFormatter(parseFloat(`${res.data.valor}`), {
          significantDigits: 2,
          thousandsSeparator: ".",
          decimalSeparator: ",",
          symbol: "",
        }));

        loadListSocios();
      })
      .catch(err => {
        if (err.response?.data?.message)
          alert(err.response?.data?.message);
        else
          alert("Erro ao tentar recuperar a configuração. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })

  }

  const loadListSocios = async () => {
    setLoading(true)
    setTxtLoading("Carregando lista de Sócios...");

    await api.get(`socio/?cancelados=notCanceled`)
      .then(res => {
        if (!res.data) throw new Error("Servidor indisponível. Tente novamente mais tarde");

        setListSocios(res.data.list);
      })
      .catch(err => {
        if (err.response?.data?.message)
          alert(err.response?.data?.message);
        else if (err.message)
          alert(err.message);
        else
          alert("Erro ao efetuar a assinatura. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      })
  }

  const handleSalvar = async () => {
    setLoading(true)
    setTxtLoading("Salvando configurações...");

    try {

      const valor = parseFloat(valorAssinatura.replace(".", "").replace(",", "."));

      await api.post("configSocioTorcedor", {
        id: configSocio?.id,
        valor,
        termo
      });

      if (parseFloat(`${valor}`) !== parseFloat(`${configSocio?.valor}`)) {
        if (window.confirm("Houve alteração no valor da assinatura. Você deseja reajustar o valor de todas as assinaturas existentes?")) {
          setTxtLoading("Recuperando lista de sócios...");
          const reqListSocios = await api.get(`socio/?cancelados=notCanceled`);
          if (!reqListSocios.data) throw new Error("Servidor indisponível. Tente novamente mais tarde");

          const totalAssinaturas: number = reqListSocios.data.total;

          Promise.all(
            reqListSocios.data.list.map(async (socio: ISocio, i: number) => {
              setTxtLoading(`Alterando assinatura ${i}/${totalAssinaturas}`);

              await apiPagarMe.put(`subscriptions/${socio.id_assinatura}/items/${socio.id_item}`, {
                pricing_scheme: {
                  scheme_type: 'Unit',
                  price: parseInt(currencyFormatter(parseFloat(`${valor}`)).replace(/\D/g, ""))
                },
                description: 'Sócio Torcedor',
                quantity: 1,
                status: "active"
              })

              const newSocio: ISocio = { ...socio, valor_atual: valor }
              await api.post('socio', { ...newSocio });

            })
          );

        }
      }

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else if (err.message)
        alert(err.message);
      else
        alert("Erro ao tentar salvar as configurações. Tente novamente mais tarde.");
    } finally {
      setLoading(false)
      setTxtLoading("");
    }

  }

  const handleGetLastCharge = async (item: ISocio) => {
    try {
      const reqGetLastCharge = await apiPagarMe.get(`subscriptions/${item.id_assinatura}`);
      if (!reqGetLastCharge.data) throw new Error("Servidor indisponível. Tente novamente mais tarde");

      const newList: ISocio[] = [];

      listSocios.forEach(rs => {
        if (rs.id === item.id)
          newList.push({ ...rs, status: reqGetLastCharge.data.status === "active" ? "ativo" : "naoAtivo" });
        else
          newList.push({ ...rs });
      });

      setListSocios(newList);

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else if (err.message)
        alert(err.message);
      else
        alert("Erro ao tentar recuperar as informações da assinatura. Tente novamente mais tarde.");
    }
  }

  const handleCancelAssinatura = async (socio: ISocio) => {
    if (!window.confirm(`Deseja realmente cancelar a assinatura do(a) ${socio.cliente.nome}?`))
      return;

    setLoading(true);
    setTxtLoading("Cancelando Assinatura...");

    try {
      const reqCancelPagarMe = await apiPagarMe.delete(`subscriptions/${socio.id_assinatura}`);
      if (!reqCancelPagarMe.data) throw new Error("Servidor indisponível. Tente novamente mais tarde");


      const dataReq = { ...socio, data_cancelamento: dateNow() };
      const reqCancelAssinatura = await api.post("socio", { ...dataReq });
      if (!reqCancelAssinatura.data) throw new Error("Servidor ticket capital indisponível. Tente novamente mais tarde");

      alert("Assinatura cancelada com sucesso!");

      loadListSocios()

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else if (err.message)
        alert(err.message);
      else
        alert("Erro ao cancelar a assinatura. Tente novamente mais tarde");

      setLoading(false);
      setTxtLoading("");
    }
  }

  useEffect(() => {
    if (tokens?.token)
      loadConfigSocio();
  }, [tokens]);

  useEffect(() => {
    const listFilter = listSocios.filter(e => !e.status);

    if (listFilter.length > 0)
      handleGetLastCharge(listFilter[0]);

  }, [listSocios])

  return (
    <div id="pageConfigSocio">

      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && configSocio && (
        <>
          <div className="configContent">

            <span style={{ width: "49%" }}>
              <label>Valor</label>
              <Input
                mask="currency"
                value={valorAssinatura}
                inputMaskChange={(v) => setValorAssinatura(v)}
              />
            </span>

            <span style={{ width: "98.5%" }}>
              <label>Termos e Condições</label>
              <textarea
                value={termo}
                onChange={(v) => setTermo(v.target.value)}
              />
            </span>

            <button className="btn-green" onClick={() => handleSalvar()}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

          </div>

          <div className="configContent">
            <h3>Lista de Sócios</h3>

            <div className="tableContext">
              <div className="headTable">
                <span style={{ width: '50px' }} />
                <span style={{ width: '80px', justifyContent: "center" }}>Status</span>
                <span style={{ width: '80px', justifyContent: "center" }}>Cadeira</span>
                <span style={{ width: '150px' }}>Desde</span>
                <span style={{ width: '150px' }}>Telefone</span>
                <span style={{ flex: '1' }}>Cliente</span>
              </div>

              <div className="contentTable">
                {listSocios.sort(dynamicSort("cliente.nome")).map(item => (
                  <div className="rowTable" key={item.id}>
                    <span style={{ width: '50px' }}>
                      <button className="btn-red" onClick={() => { handleCancelAssinatura(item) }}>
                        <FontAwesomeIcon icon={faCancel} />
                      </button>
                    </span>
                    <span style={{ width: '80px', justifyContent: "center" }}>
                      {item.status && (
                        <div className={`${item.status === "ativo" ? "statusOn" : "statusOff"}`} />
                      )}
                    </span>
                    <span style={{ width: '80px', justifyContent: "center" }}>{item.nr_cadeira}</span>
                    <span style={{ width: '150px' }}>{convertDateToBr(item.data_inicio)}</span>
                    <span style={{ width: '150px' }}>{maskPhone(item.cliente.telefone.toString())}</span>
                    <span style={{ flex: '1' }}>{item.cliente.nome}</span>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </>
      )}

    </div>
  )
}