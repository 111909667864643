import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { Load } from "../../components/Load";
import { ICidades, IUsuariosCidades } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";

import "./style.css";

type IProps = {
  idUser: string;
  closeModal: () => void;
}

export function CidadesUsuario({ idUser, closeModal }: IProps) {
  const { checkToken } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");
  const [tipoForm, setTipoForm] = useState<"" | "Novo">("");

  const [listCidades, setListCidades] = useState<ICidades[]>([]);
  const [list, setList] = useState<IUsuariosCidades[]>([]);

  const [cidadeSelected, setCidadesSelected] = useState("");

  const [userName, setUserName] = useState("");

  const loadList = async () => {
    setLoading(true);
    setTxtLoading("Carregando lista...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    try {

      if (listCidades.length <= 0) {
        const reqListCadCidade = await api.get("cidades");
        if (reqListCadCidade.data)
          setListCidades(reqListCadCidade.data.result);
      }

      const reqUser = await api.get(`users/${idUser}`);

      if (reqUser.data) {
        setUserName(reqUser.data.nome);
        setList(reqUser.data.cidades);
      }

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao tentar recuperar a lista. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
      setTxtLoading("");
    }

  }

  const handleNovo = () => {
    setCidadesSelected("");
    setTipoForm("Novo");
  }

  const handleSave = async () => {
    setLoading(true);
    setTxtLoading("Cadastrando cidade...");

    console.log(cidadeSelected);

    try {
      await api.post("users/cidade", {
        user_id: idUser,
        id_cidade: cidadeSelected
      });

      loadList();

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao cadastrar cidade. Tente novamente mais tarde.");

      setLoading(false);
      setTxtLoading("");
    }

  }

  const handleDelete = async (id: string) => {
    if (!window.confirm("Deseja relamente exlcuir essa cidade?"))
      return;

    setLoading(true);
    setTxtLoading("Excluindo cidade...");

    try {

      await api.delete("users/cidade", { data: { id } });
      loadList();

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao cadastrar cidade. Tente novamente mais tarde.");

      setLoading(false);
      setTxtLoading("");
    }

  }

  useEffect(() => {
    if (idUser.length > 0)
      loadList();
  }, [idUser])

  return (
    <div id="modalCidadesUsuario">
      <div className="title">
        <p>Cidades: {userName}</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="info">
              <h4>
                {tipoForm === "Novo" && (`Cadastrar`)}
              </h4>

              {tipoForm === "" && (
                <button className="btn-green btnNovo" onClick={handleNovo}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
            </div>

            {tipoForm !== "" && (
              <div className="divFormModal">
                <select value={cidadeSelected} onChange={(e) => setCidadesSelected(e.target.value)}>
                  <option value=""></option>
                  {listCidades.map((item) => (
                    <option key={item.id} value={item.id}>{item.nome}</option>
                  ))}
                </select>

                <button className="btn-green" onClick={handleSave}>
                  <FontAwesomeIcon icon={faSave} />
                </button>

              </div>
            )}

            <div className="headTable">
              <span>Nome</span>
            </div>
            <div className="contentTable">
              {list && list.map((item) => (
                <div className="rowTable" key={item.id}>
                  <span>
                    <button className="btn-red" onClick={() => handleDelete(item.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    {listCidades.filter(i => i.id === item.id_cidade)[0].nome}/{listCidades.filter(i => i.id === item.id_cidade)[0].estado}
                  </span>
                </div>
              ))}
            </div>

          </>
        )}
      </div>

    </div>
  )
}