import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { ICidades, ICreateCidade } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";

import "./style.css";

type IProps = {
  tipoForm: "Edit" | "New";
}

type IParams = {
  idCidade: string;
}

export function CidadesFormAdm({ tipoForm }: IProps) {
  const navigate = useNavigate();
  const { idCidade } = useParams() as IParams;
  const { tokens, checkToken } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [cadastro, setCadastro] = useState<ICreateCidade>({} as ICreateCidade);

  const loadCidade = async () => {
    setLoading(true);
    setTxtLoading("Carregando cidade...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    await api.get(`cidades/${idCidade}`)
      .then((res) => {
        if (!res.data)
          return;

        const cad = res.data as ICidades;

        setCadastro({
          id: cad.id,
          nome: cad.nome,
          estado: cad.estado
        });

      })
      .catch((err) => {
        if (err.response?.data?.message)
          alert(err.response?.data?.message);
        else
          alert("Erro ao tentar recuperar a lista de cidades. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });

  };

  const handleSave = async () => {
    setLoading(true);
    setTxtLoading("Salvando cidade...");

    try {

      if (cadastro.nome.length < 3 || cadastro.estado.length !== 2)
        return alert("Preencha todos os campos corretamente");

      const canLoad = checkToken();
      if (!canLoad)
        return;

      const res = await api.post(`cidades`, { ...cadastro });

      if (!res.data) return;

      navigate(-1);

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response.data.message);
      else
        alert("Erro ao salvar cidade. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
      setTxtLoading("");
    }
  }

  useEffect(() => {
    if (tipoForm === "Edit" && tokens?.token)
      loadCidade();
  }, [tipoForm, tokens]);

  return (
    <div id="pageCidadesForm">

      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && (
        <div className="body">

          <span style={{ flex: 1 }}>
            <label>Nome</label>
            <Input
              mask="none"
              value={cadastro.nome}
              inputMaskChange={(v) => setCadastro(e => ({ ...e, nome: v }))}
            />
          </span>

          <span style={{ flex: 1 }}>
            <label>Estado</label>
            <Input
              mask="none"
              value={cadastro.estado}
              inputMaskChange={(v) => setCadastro(e => ({ ...e, estado: v.toUpperCase() }))}
              maxLength={2}
            />
          </span>

          <span className="btnContainer">
            <button className="btn-green" onClick={() => handleSave()}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btn-purple" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </span>

        </div>
      )}

    </div>
  )
}