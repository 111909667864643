import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faTicket, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { ICreateEventoSetor, IEventos, IEventosSetor, IEventosSetorLote } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import Modal from "react-modal";

import "./style.css";
import { defaultModalStyle } from "../../utils/defaultModalStyle";
import { EventoLotes } from "../EventoLotes";
import { getTipoSetor } from "../../utils/getTipoSetor";

type IProps = {
  idEvento: string;
  closeModal: () => void;
}

export function EventoSetores({ closeModal, idEvento }: IProps) {
  const { checkToken } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [evento, setEvento] = useState<IEventos>({} as IEventos);
  const [cadastro, setCadastro] = useState<ICreateEventoSetor>({} as ICreateEventoSetor);

  const [openModalLotes, setOpenModalLotes] = useState(false);
  const [setorSelected, setSetorSelected] = useState<IEventosSetor>({} as IEventosSetor);

  const loadEvento = useCallback(async (eventoID: string) => {
    console.log("loadEvento");

    setLoading(true);
    setTxtLoading("Carregando evento...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    try {
      const resEvento = await api.get(`eventos/${eventoID}`)

      if (!resEvento.data)
        throw new Error("Não foi possível recuperar as informações do Servidor.");

      setEvento(resEvento.data);

      setLoading(false);
      setTxtLoading("");

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao carregar evento. Tente novamente mais tarde");

      closeModal();
    }
  }, []);

  const handleDelete = async (setor: IEventosSetor) => {
    if (!window.confirm(`Deseja realmente excluir o setor: ${setor.nome}?`))
      return;

    setLoading(true);
    setTxtLoading("Excluindo setor...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    try {
      await api.delete("eventos/setor", {
        data: { id: setor.id }
      })
      loadEvento(idEvento);
    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao carregar evento. Tente novamente mais tarde");

      setLoading(false);
      setTxtLoading("");
    }

  }

  const handleSave = async () => {
    console.log(!cadastro.tipo ? "hummmmm" : "_|_");

    if (!cadastro.nome || cadastro.nome.length < 3)
      return alert("Preecha o nome do Setor corretamente");


    if (!cadastro.tipo || cadastro.tipo < 0)
      return alert("Selecione o tipo do setor");

    setLoading(true);
    setTxtLoading("Cadastrando setor...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    try {
      await api.post("eventos/setor", {
        ...cadastro,
        id_evento: idEvento,
        qtd_por_linha: cadastro.qtd_por_linha > 0 ? cadastro.qtd_por_linha : 0,
        qtd_lugar: cadastro.qtd_lugar > 0 ? cadastro.qtd_lugar : 1,
        dividir_ate: cadastro.dividir_ate > 0 ? cadastro.dividir_ate : 1
      });

      loadEvento(idEvento);

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao carregar evento. Tente novamente mais tarde");

      setLoading(false);
      setTxtLoading("");
    }

  }

  const handleOpenModalLotes = (item: IEventosSetor) => {
    setSetorSelected(item);
    setOpenModalLotes(true);
  }

  const attListLotes = (newValues: IEventosSetorLote[]) => {
    if (!evento) return;

    const newListSetores: IEventosSetor[] = [];
    evento.setores.forEach(item => {
      if (item.id === setorSelected.id) {
        newListSetores.push({ ...item, lotes: newValues });
        setSetorSelected(e => ({ ...e, lotes: newValues }));
      } else
        newListSetores.push(item);
    });

    setEvento(e => ({ ...e, setores: newListSetores }));
  }

  useEffect(() => {
    if (idEvento.length > 0)
      loadEvento(idEvento);
  }, [idEvento, loadEvento]);

  return (
    <div id="modalEventoSetores">
      <div className="title">
        <p>Setores: {evento?.nome}</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} options={{ width: 100, height: 100 }} />
        )}

        {!loading && (
          <>
            <div className="divFormModal">
              <span style={{ width: "49%" }}>
                <label>Nome do Setor</label>
                <Input
                  mask="none"
                  value={cadastro.nome ? cadastro.nome : ""}
                  inputMaskChange={(v) => setCadastro(e => ({ ...e, nome: v }))}
                />
              </span>

              <span style={{ width: "49%" }}>
                <label>Tipo</label>
                <select value={cadastro.tipo} onChange={(v) => setCadastro(e => ({ ...e, tipo: parseInt(v.target.value) }))}>
                  <option value="0">Selecione</option>
                  <option value="1">Mesas</option>
                  <option value="2">Individual</option>
                  <option value="3">Posição</option>
                </select>
              </span>

              <span style={{ width: "32%" }}>
                <label>Dividir até:</label>
                <Input
                  mask="none"
                  value={cadastro.dividir_ate ? cadastro.dividir_ate : ""}
                  inputMaskChange={(v) => setCadastro(e => ({ ...e, dividir_ate: parseInt(v) }))}
                  type="number"
                  min={1}
                />
              </span>

              {(cadastro.tipo === 1 || cadastro.tipo === 3) && (
                <>
                  <span style={{ width: "32%" }}>
                    <label>Lugares:</label>
                    <Input
                      mask="none"
                      value={cadastro.qtd_lugar ? cadastro.qtd_lugar : ""}
                      inputMaskChange={(v) => setCadastro(e => ({ ...e, qtd_lugar: parseInt(v) }))}
                      type="number"
                      min={1}
                    />
                  </span>

                  <span style={{ width: "32%" }}>
                    <label>Por fileira:</label>
                    <Input
                      mask="none"
                      value={cadastro.qtd_por_linha ? cadastro.qtd_por_linha : ""}
                      inputMaskChange={(v) => setCadastro(e => ({ ...e, qtd_por_linha: parseInt(v) }))}
                      type="number"
                      min={1}
                    />

                  </span>
                </>
              )}
            </div>

            <button className="btn-green" style={{ margin: "0 auto" }} onClick={() => handleSave()}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <div className="headTable">
              <span style={{ width: "85px" }}></span>
              <span style={{ flex: 2 }}>Nome</span>
              <span style={{ flex: 1 }}>Tipo</span>
            </div>
            <div className="contentTable">
              {evento?.setores && evento?.setores.map((item) => {
                if (!item.excluir) {
                  return (
                    <div className="rowTable" key={item.id}>
                      <span style={{ width: "85px" }}>
                        <button className="btn-red" onClick={() => handleDelete(item)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                        <button className="btn-blue" onClick={() => handleOpenModalLotes(item)}>
                          <FontAwesomeIcon icon={faTicket} />
                        </button>
                      </span>
                      <span style={{ flex: 2 }}>
                        {item.nome}
                      </span>
                      <span style={{ flex: 1 }}>
                        {getTipoSetor(item.tipo)}
                      </span>
                    </div>
                  )
                }
              })}
            </div>

          </>
        )}

      </div>

      <Modal isOpen={openModalLotes} style={
        {
          content: { ...defaultModalStyle.content, width: window.innerWidth > 800 ? 800 : window.innerWidth - 30 },
          overlay: { ...defaultModalStyle.overlay }
        }
      }>
        <EventoLotes closeModal={() => setOpenModalLotes(false)} setor={setorSelected} attListLotes={(v) => attListLotes(v)} />
      </Modal>

    </div>
  )
}