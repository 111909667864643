import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBoVY88-LtymYjlAvUfNSeK-oyQtYarOMA",
  authDomain: "ticketcapital-4033a.firebaseapp.com",
  projectId: "ticketcapital-4033a",
  storageBucket: "ticketcapital-4033a.appspot.com",
  messagingSenderId: "87748592711",
  appId: "1:87748592711:web:64c6ae256d053658bbdd62"
};

const firebase = initializeApp(firebaseConfig);