import { MinhaContaLayout } from "../../components/MinhaContaLayout";

import "./style.css";

export function MinhaConta() {

  return (
    <MinhaContaLayout>
      <>
      </>
    </MinhaContaLayout>
  );
}