import { faHandPointer } from "@fortawesome/free-regular-svg-icons";
import { faAngleDoubleLeft, faAngleDoubleRight, faCheckDouble, faSearch, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { IEventos, IEventosVendas, IFiltersVendasClientes } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import { convertDateToBr } from "../../utils/dateProvider";
import { getTipoSetor } from "../../utils/getTipoSetor";
import { maskCpfCnpj } from "../../utils/masks";

import "./style.css";

export function IngressosAdm() {
  const { checkToken, tokens } = useAdmContext();
  const { idIngresso } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [listEventos, setListEventos] = useState<IEventos[]>([]);
  const [listVendas, setListVendas] = useState<IEventosVendas[]>([]);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [limitPage, setLimitPage] = useState(25);

  const [nomeEvento, setNomeEvento] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");

  const [eventoSelected, setEventoSelected] = useState<IEventos>({} as IEventos);

  const [mostraIngresso, setMostraIngresso] = useState(false);
  const [mostraPesquisa, setMostraPesquisa] = useState(true);
  const [mostraEvento, setMostraEvento] = useState(false);

  const handleNomeEventoKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      loadListEventos();
  }

  const loadListEventos = async () => {
    setLoading(true);
    setTxtLoading("Procurando Eventos...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    await api.post(`eventos/list`, {
      pesquisa: {
        nome: nomeEvento
      },
      cursor: (limitPage * page) - limitPage,
      limit: limitPage
    })
      .then((res) => {
        if (!res.data)
          return;

        const newMaxPage = Math.ceil(res.data.total / limitPage);

        setListEventos(res.data.result);
        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          alert(err.response?.data?.message);
        else
          alert("Erro ao carregar lista de eventos. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleSelectEvento = (evento: IEventos) => {
    setEventoSelected(evento);
    setMostraEvento(true);
    setMostraPesquisa(false);
  }

  const handleLoadVendas = async () => {

    if (telefone.length !== 15 && cpf.length !== 14 && !email.includes("@") && email.length <= 5)
      return alert("Preencha ao menos um campo de pesquisa corretamente");

    setLoading(true);
    setTxtLoading("Procurando por ingrerssos...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    let idCliente: string | undefined = undefined;
    if (telefone.length === 15) {
      try {
        const reqCliente = await api.get(`clientes/phone/${telefone.replace(/[^0-9]/g, "")}`);

        if (!reqCliente.data)
          throw new Error("Erro ao recuperar as informações do Servidor");

        idCliente = reqCliente.data.id;
      } catch (err: any) {
        if (err.response?.data?.message)
          return alert(err.response?.data?.message);
        else
          return alert("Erro ao recuperar informações do cliente.")
      }
    }

    let filters: IFiltersVendasClientes = {} as IFiltersVendasClientes;

    if (idCliente)
      filters = { ...filters, idCliente };

    if (cpf.length === 14)
      filters = { ...filters, cpf: parseInt(cpf.replace(/[^0-9]/g, "")) };

    if (email.length > 5)
      filters = { ...filters, email };

    await api.post("eventos/vendas/cliente", { ...filters })
      .then(res => {
        if (!res.data) return;

        setListVendas(res.data.result);
      })
      .catch(err => {
        if (err.response?.data?.message)
          alert(err.response.data.message);
        else
          alert("Erro ao recuperar lista de ingressos");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }

  const handleVoltar = () => {
    setMostraIngresso(false);
    setMostraEvento(false)
    setMostraPesquisa(true);
    setListVendas([]);
    navigate("/adm/ingressos");
  }

  const handleUtilizado = async (ingresso: IEventosVendas) => {
    if (!window.confirm("Deseja realmente marcar este ingresso como Entregue/Utilizado?"))
      return;

    setLoading(true);
    setTxtLoading("Alterando ingresso para entregue/utilizado");

    try {
      await api.post("eventos/venda", { ...ingresso, entregue: true });
    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response?.data?.message);
      else
        alert("Erro ao alterar ingresso para entregue/utilizado");
    }
    finally {
      if (idIngresso) {
        setLoading(false);
        setTxtLoading("");
        handleVoltar();
      } else
        handleLoadVendas();
    }
  }

  const handleClienteKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleLoadVendas();
  }

  const handleLoadIngresso = async () => {
    setMostraIngresso(true);
    setMostraEvento(false);
    setMostraPesquisa(false);

    setLoading(true);
    setTxtLoading("Carregando ingresso...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    try {
      const reqVenda = await api.get(`eventos/venda/${idIngresso}`);

      if (!reqVenda.data) throw new Error("Erro ao carregar ingresso");

      const resIngresso = reqVenda.data as IEventosVendas;

      if (resIngresso.evento)
        setEventoSelected(resIngresso.evento);

      setListVendas([resIngresso]);

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response.data.message);
      else
        alert("Erro ao carregar ingresso.");
    } finally {
      setLoading(false);
      setTxtLoading("");
    }
  }

  useEffect(() => {
    if (idIngresso && tokens?.token)
      handleLoadIngresso();
  }, [idIngresso, tokens]);

  useEffect(() => {
    if (nomeEvento.length > 0)
      loadListEventos();
  }, [page]);

  return (
    <div id="pageIngressos">

      {mostraPesquisa && (
        <>
          <div className="tableContext">
            <p>Pesquisa</p>
            <hr />

            <div className="form">
              <span style={{ width: "100%" }}>
                <label>Nome</label>
                <Input
                  mask="none"
                  value={nomeEvento}
                  inputMaskChange={(v) => setNomeEvento(v)}
                  placeholder="Pesquise o nome do evento"
                  onKeyUp={(e) => handleNomeEventoKeyUp(e)}
                />
              </span>
            </div>

            <button className="btn-blue" style={{ margin: "15px auto 0px auto" }} onClick={() => loadListEventos()}>
              <FontAwesomeIcon icon={faSearch} />
              Pesquisar
            </button>
          </div>

          {loading && (
            <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
          )}

          {!loading && listEventos.length > 0 && (
            <>
              <div className="tableContext">
                <div className="headTable">
                  <span style={{ width: "70px" }}></span>
                  <span style={{ flex: '2' }}>Nome</span>
                  <span style={{ flex: '2' }}>Cidade</span>
                  <span style={{ flex: '1', justifyContent: "center" }}>Data</span>
                </div>

                <div className="contentTable">
                  {listEventos.map(item => (
                    <div className="rowTable" key={item.id}>
                      <span style={{ width: "60px", justifyContent: "center" }}>
                        <button className="btn-green" onClick={() => handleSelectEvento(item)}>
                          <FontAwesomeIcon icon={faHandPointer} />
                        </button>
                      </span>
                      <span style={{ flex: '2' }}>{item.nome}</span>
                      <span style={{ flex: '2' }}>{item.cidade.nome}/{item.cidade.estado}</span>
                      <span style={{ flex: '1', justifyContent: "center" }}>{convertDateToBr(item.data)}</span>
                    </div>
                  ))}
                </div>

              </div>

              <div className="pagination">
                {page !== 1 && (
                  <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => setPage(i => --i)} />
                )}
                <p>
                  {page}/{maxPage}
                </p>
                {page !== maxPage && (
                  <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => setPage(i => ++i)} />
                )}
              </div>
            </>
          )}

        </>
      )}

      {mostraEvento && (
        <>
          <button className="btnVoltar btn-purple" onClick={() => handleVoltar()}>
            <FontAwesomeIcon icon={faUndo} />
            VOLTAR
          </button>

          <div className="tableContext">
            <div className="eventoDetail">
              <img src={`${api.defaults.baseURL}/eventoImg/${eventoSelected.id}.jpg`} height={100} />
              <div className="infoEvento">
                <h2>{eventoSelected.nome}</h2>
                <p>
                  <b>Data Evento:</b> {convertDateToBr(eventoSelected.data)}
                </p>
                <p>
                  {eventoSelected.local.toUpperCase()} - {eventoSelected.cidade.nome.toUpperCase()}/{eventoSelected.cidade.estado.toUpperCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="tableContext">
            <p>Pesquisa por Cliente</p>
            <hr />

            <div className="form">
              <span style={{ flex: "1" }}>
                <label>Telefone</label>
                <Input
                  mask="phone"
                  value={telefone}
                  inputMaskChange={(v) => setTelefone(v)}
                  placeholder="Pesquisa por telefone"
                  onKeyUp={(e) => handleClienteKeyUp(e)}
                />
              </span>
              <span style={{ flex: "1" }}>
                <label>CPF</label>
                <Input
                  mask="cpf_cnpj"
                  value={cpf}
                  inputMaskChange={(v) => setCpf(v)}
                  placeholder="Pesquisa por CPF"
                  maxLength={14}
                  onKeyUp={(e) => handleClienteKeyUp(e)}
                />
              </span>
              <span style={{ width: "100%" }}>
                <label>E-mail</label>
                <Input
                  mask="none"
                  value={email}
                  inputMaskChange={(v) => setEmail(v)}
                  placeholder="Pesquisa por E-mail"
                  onKeyUp={(e) => handleClienteKeyUp(e)}
                />
              </span>
            </div>

            <button className="btn-blue" style={{ margin: "15px auto 0px auto" }} onClick={() => handleLoadVendas()}>
              <FontAwesomeIcon icon={faSearch} />
              Pesquisar
            </button>
          </div>

          {loading && (
            <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
          )}

          {!loading && listVendas.length > 0 && (
            <div className="tableContext">
              <div className="contextIngressos">
                {listVendas.map(item => (
                  <div key={item.id} className="cardIngresso">
                    <p>{item.cliente.nome}</p>
                    <p>{item.lote?.setor?.nome}</p>
                    <p>{item.lote?.setor?.tipo ? `( ${getTipoSetor(item.lote?.setor?.tipo)} )` : ""}</p>

                    {item.status.includes("paid") && (
                      <>
                        {!item.entregue && (
                          <>
                            <div className="tag paid">PAGO</div>
                            <button className="btn-green" onClick={() => handleUtilizado(item)}>
                              <FontAwesomeIcon icon={faCheckDouble} />
                              Entregar / Utillizar
                            </button>
                          </>
                        )}

                        {item.entregue && (
                          <div className="tag paid">UTILIZADO</div>
                        )}
                      </>
                    )}

                    {item.status.includes("failed") && (
                      <div className="tag failed">NÃO PAGO</div>
                    )}

                    {(item.status.includes("waiting_payment") || item.status.includes("pending")) && (
                      <div className="tag pending">AGUARDANDO PAGAMENTO</div>
                    )}

                  </div>
                ))}
              </div>
            </div>
          )}

        </>
      )}

      {mostraIngresso && (
        <>
          {loading && (
            <Load txtLoading={txtLoading} options={{ width: 150, height: 150 }} />
          )}

          {!loading && (
            <>
              <button className="btnVoltar btn-purple" onClick={() => handleVoltar()}>
                <FontAwesomeIcon icon={faUndo} />
                VOLTAR
              </button>

              <div className="tableContext">
                <div className="eventoDetail">
                  <img src={`${api.defaults.baseURL}/eventoImg/${eventoSelected.id}.jpg`} height={100} />
                  <div className="infoEvento">
                    <h2>{eventoSelected.nome}</h2>
                    <p>
                      <b>Data Evento:</b> {convertDateToBr(eventoSelected.data)}
                    </p>
                    <p>
                      {eventoSelected.local.toUpperCase()} - {eventoSelected.cidade.nome.toUpperCase()}/{eventoSelected.cidade.estado.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>

              {listVendas.length > 0 && (
                <div className="tableContext">
                  <div className="contextIngressos">
                    {listVendas.map(item => (
                      <div key={item.id} className="cardIngresso">
                        <p>{item.cliente.nome}</p>
                        <p>{maskCpfCnpj(`${item.cpf_cliente}`)}</p>
                        <p>{item.email_cliente}</p>
                        <p>{item.lote?.setor?.nome}</p>
                        <p>{item.lote?.setor?.tipo ? `( ${getTipoSetor(item.lote?.setor?.tipo)} )` : ""}</p>

                        {item.status.includes("paid") && (
                          <>
                            {!item.entregue && (
                              <>
                                <div className="tag paid">PAGO</div>
                                <button className="btn-green" onClick={() => handleUtilizado(item)}>
                                  <FontAwesomeIcon icon={faCheckDouble} />
                                  Entregar / Utillizar
                                </button>
                              </>
                            )}

                            {item.entregue && (
                              <div className="tag used">UTILIZADO</div>
                            )}
                          </>
                        )}

                        {item.status.includes("failed") && (
                          <div className="tag failed">NÃO PAGO</div>
                        )}

                        {(item.status.includes("waiting_payment") || item.status.includes("pending")) && (
                          <div className="tag pending">AGUARDANDO PAGAMENTO</div>
                        )}

                      </div>
                    ))}
                  </div>
                </div>
              )}

            </>
          )}

        </>
      )}

    </div>
  )
}