import {
  faCashRegister,
  faTicket,
  faTrash
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Load } from "../../components/Load"
import {
  ICreateEventosVendas,
  IEventos,
  IEventosSetor,
  IEventosSetorLote,
  IEventosVendas
} from "../../global/types"
import { useSiteContext } from "../../hooks/useSiteContext"
import { api } from "../../services/api"
import { currencyFormatter } from "../../utils/currencyFormatter"
import {
  compareInDays,
  convertDateSqlToDate,
  convertToUTC,
  dateNow
} from "../../utils/dateProvider"
import { dynamicSort } from "../../utils/dynamicSort"
import { getTipoSetor } from "../../utils/getTipoSetor"

import "./style.css"

type ISetorSelected = {
  setor: IEventosSetor
  carregandoVendas: boolean
}

export function EventoDetalhes() {
  const navigate = useNavigate()
  const { idEvento } = useParams()
  const { saveCarrinho, cliente, carrinho: carrinhoContext } = useSiteContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [evento, setEvento] = useState<IEventos>({} as IEventos)
  const [setorSelected, setSetorSelected] = useState<ISetorSelected>(
    {} as ISetorSelected
  )

  const [carrinho, setCarrinho] = useState<ICreateEventosVendas[]>([])
  const [totalCarrinho, setTotalCarrinho] = useState(0)
  const [vendas, setVendas] = useState<IEventosVendas[]>([])
  const [mesaSelected, setMesaSelected] = useState(0)

  const [carregandoVendas, setCarregandoVendas] = useState(false)

  const [compraLiberada, setCompraLiberada] = useState(true)

  const handleSelectSetor = (setor: IEventosSetor) => {
    if (carregandoVendas) return

    setMesaSelected(0)
    setSetorSelected({
      setor,
      carregandoVendas: false
    })
  }

  const handleAddCarrinho = (
    lote: IEventosSetorLote,
    nrLugar: number,
    nrLote: number
  ) => {
    if (!lote.compraLiberada) return

    const qtdRestante = lote.qtdRestante ? lote.qtdRestante : 0

    if (
      carrinho.filter((i) => i.id_evento_setor_lote === lote.id).length >=
      qtdRestante
    )
      return

    const newCarrinho: ICreateEventosVendas = {
      setor: setorSelected.setor,
      id_evento: evento.id,
      id_evento_setor_lote: lote.id,
      nr_lugar: nrLugar,
      valor: parseFloat(`${lote.valor}`) + parseFloat(`${lote.taxa_adm}`),
      nomeEvento: evento.nome,
      nrLote
    }

    setMesaSelected(0)
    setCarrinho((e) => [newCarrinho, ...e])
  }

  const loadVendas = async () => {
    let loteAtual: Date | null = null
    setorSelected.setor.lotes.map((item) => {
      if (dateNow() >= convertDateSqlToDate(`${item.data}`))
        loteAtual = item.data
    })

    setCarregandoVendas(true)

    await Promise.allSettled(
      setorSelected.setor.lotes.map(async (item) => {
        if (item.vendasCarregadas) return

        let totalVendas = 0

        try {
          if (
            setorSelected.setor.tipo === 1 ||
            setorSelected.setor.tipo === 3
          ) {
            // MESA ou POSIÇÃO
            const reqVendas = await api.post("eventos/vendas/list", {
              id: setorSelected.setor.id_evento,
              setor: setorSelected.setor.id
            })
            totalVendas = reqVendas.data.total
            setVendas(reqVendas.data.result)
          } else {
            const reqVendas = await api.post("eventos/vendas/count", {
              id: item.id
            })
            totalVendas = reqVendas.data
          }

          const newList: IEventosSetorLote[] = []
          setorSelected.setor.lotes.map((rs) => {
            if (rs.id === item.id) {
              const qtdRestante = rs.qtd_total - totalVendas
              newList.push({
                ...rs,
                vendasCarregadas: true,
                qtdRestante: qtdRestante,
                compraLiberada: qtdRestante > 0 && rs.data === loteAtual
              })
            } else newList.push(rs)
          })

          setSetorSelected((e) => ({
            setor: { ...e.setor, lotes: newList },
            carregandoVendas: true
          }))
        } catch (err: any) {
          if (err.response?.data?.message)
            console.log(err.response?.data?.message)
          else console.log(err)
        }
      })
    )

    setCarregandoVendas(false)
  }

  const loadEvento = async () => {
    if (!idEvento) return

    setLoading(true)
    setTxtLoading("Carregando evento...")

    try {
      const res = await api.get(`eventos/${idEvento}`)

      if (!res.data) return

      // valor negativo = já passou o dia do evento
      const diaDif = compareInDays(dayjs().toDate(), res.data.data)
      if (diaDif < 0) setCompraLiberada(false)

      setEvento(res.data)
    } catch (err: any) {
      if (err.response?.data?.message) console.log(err.response?.data?.message)

      alert("Erro ao carregar evento. Tente novamente mais tarde")
    } finally {
      setLoading(false)
      setTxtLoading("")
    }
  }

  const deleteCarrinho = (index: number) => {
    if (!window.confirm("Deseja realmente retirar este ingresso do carrinho?"))
      return

    const newList: ICreateEventosVendas[] = []

    carrinho.map((item, i) => {
      if (index !== i) newList.push(item)
    })

    setCarrinho(newList)
  }

  const handleMesaSelected = (nrMesa: number, idLote: string) => {
    if (
      vendas.filter(
        (x) => x.id_evento_setor_lote === idLote && x.nr_lugar === nrMesa
      ).length > 0
    )
      return
    if (
      carrinho.filter(
        (x) => x.id_evento_setor_lote === idLote && x.nr_lugar === nrMesa
      ).length > 0
    )
      return

    setMesaSelected(nrMesa)
  }

  const handleComprar = () => {
    if (carrinhoContext)
      saveCarrinho([
        ...carrinho.filter((i) => !i.notDelete),
        ...carrinhoContext
      ])
    else saveCarrinho(carrinho)

    if (cliente?.id && cliente.id.length > 5) navigate("/carrinho")
    else navigate("/cliente/login", { state: { goTo: "/carrinho" } })
  }

  useEffect(() => {
    if (setorSelected.setor && !setorSelected.carregandoVendas) loadVendas()
  }, [setorSelected])

  useEffect(() => {
    if (idEvento && idEvento.length > 0) loadEvento()
  }, [idEvento])

  useEffect(() => {
    let nTotal = 0

    carrinho.map((item) => {
      nTotal += parseFloat(`${item.valor}`)
    })

    setTotalCarrinho(nTotal)
  }, [carrinho])

  useEffect(() => {
    if (!carrinhoContext || carrinhoContext.length <= 0) return

    const newList: ICreateEventosVendas[] = []
    carrinhoContext
      .filter((i) => i?.id_evento === idEvento)
      .map((item) => {
        return newList.push({ ...item, notDelete: true })
      })

    setCarrinho(newList)
  }, [carrinhoContext, idEvento])

  return (
    <div id="pageEventoDetalhes">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && evento && (
        <>
          <div className="bodyEvento">
            <div>
              {evento.id && (
                <img
                  src={`${api.defaults.baseURL}/eventoImg/${evento.id}.jpg`}
                  alt=""
                />
              )}
            </div>
            <div>
              <h2>{evento.nome}</h2>
              <h4>{evento.obs}</h4>
              <p style={{ whiteSpace: "pre-line" }}>{evento.descricao}</p>
            </div>
          </div>
          <div className="setores">
            <h2>Setores</h2>

            <div className="content">
              {evento?.setores
                ?.filter((e) => !e.excluir)
                .sort(dynamicSort("nome"))
                .map((item) => (
                  <div
                    className={`cardSetor ${
                      setorSelected?.setor?.id &&
                      setorSelected?.setor?.id === item.id
                        ? "selected"
                        : ""
                    }`}
                    key={item.id}
                    onClick={() => handleSelectSetor(item)}
                  >
                    <h4>{item.nome}</h4>
                    <p>{getTipoSetor(item.tipo)}</p>
                  </div>
                ))}
            </div>
          </div>

          {setorSelected?.setor?.lotes &&
            setorSelected?.setor?.lotes?.length > 0 && (
              <div className="lotes">
                <span style={{ width: "100%" }}>
                  <h2>Lotes</h2>
                </span>

                {setorSelected.setor.lotes.map((item, i) => (
                  <div className="cardLote" key={item.id}>
                    <div>
                      <h4>Lote {i + 1}</h4>

                      {item.compraLiberada && (
                        <>
                          {item.qtdRestante && item.qtdRestante <= 15 && (
                            <p>
                              {setorSelected.setor.tipo === 3
                                ? "Vagas"
                                : "Ingressos"}{" "}
                              restantes: {item.qtdRestante}
                            </p>
                          )}
                        </>
                      )}

                      <p>
                        Valor: {currencyFormatter(parseFloat(`${item.valor}`))}
                      </p>
                      <p>
                        Taxa Administrativa:{" "}
                        {currencyFormatter(parseFloat(`${item.taxa_adm}`))}
                      </p>
                      <p>Vendas liberadas em: {convertToUTC(item.data)}</p>
                    </div>

                    <div>
                      {!item.vendasCarregadas && (
                        <Load
                          txtLoading=""
                          options={{ height: 80, width: 80 }}
                        />
                      )}

                      {item.vendasCarregadas &&
                        setorSelected.setor.tipo !== 1 &&
                        setorSelected.setor.tipo !== 3 &&
                        compraLiberada && (
                          <button
                            disabled={!item.compraLiberada}
                            onClick={() => handleAddCarrinho(item, 0, i + 1)}
                          >
                            <FontAwesomeIcon icon={faTicket} />
                            ADICIONAR
                          </button>
                        )}
                    </div>

                    {(setorSelected.setor.tipo === 1 ||
                      setorSelected.setor.tipo === 3) &&
                      item.vendasCarregadas && (
                        <>
                          <p className="titleSelecMesa">
                            Selecione a{" "}
                            {getTipoSetor(
                              setorSelected.setor.tipo
                            ).toLowerCase()}{" "}
                            desejada:
                          </p>
                          <div className="mesas">
                            <span style={{ width: "100%", fontSize: "18px" }}>
                              {setorSelected.setor.tipo === 3
                                ? "Posição"
                                : "Palco/Show"}
                            </span>

                            {(() => {
                              let rows = []
                              for (let y = 1; y <= item.qtd_total; y++) {
                                rows.push(
                                  <span
                                    key={`mesa-${y}`}
                                    className={`
                                  ${mesaSelected === y ? "selected" : ""}
                                  ${
                                    vendas.filter(
                                      (x) =>
                                        x.id_evento_setor_lote === item.id &&
                                        x.nr_lugar === y &&
                                        (x.status === "paid" ||
                                          x.status === "order.paid")
                                    ).length > 0
                                      ? "sold"
                                      : ""
                                  }
                                  ${
                                    carrinho.filter(
                                      (x) =>
                                        x.id_evento_setor_lote === item.id &&
                                        x.nr_lugar === y
                                    ).length > 0
                                      ? "sold"
                                      : ""
                                  }
                                `}
                                    style={{
                                      width: `calc((100% / ${setorSelected.setor.qtd_por_linha}) - 10px )`
                                    }}
                                    onClick={() =>
                                      handleMesaSelected(y, item.id)
                                    }
                                  >
                                    {y}
                                  </span>
                                )
                              }
                              return rows
                            })()}
                          </div>

                          {mesaSelected > 0 &&
                            item.vendasCarregadas &&
                            item.compraLiberada &&
                            compraLiberada && (
                              <button
                                disabled={!item.compraLiberada}
                                style={{ margin: "15px auto 0px auto" }}
                                onClick={() =>
                                  handleAddCarrinho(item, mesaSelected, i + 1)
                                }
                              >
                                <FontAwesomeIcon icon={faTicket} />
                                ADICIONAR
                              </button>
                            )}
                        </>
                      )}
                  </div>
                ))}
              </div>
            )}

          {carrinho.length > 0 && (
            <div className="carrinho">
              <p className="infoComprarMais">
                Para comprar mais ingressos clique no botão adicionar
              </p>
              <span style={{ width: "100%" }}>
                <h2>Carrinho</h2>
              </span>

              {carrinho.map((item, i) => (
                <div className="cardCarrinho" key={`car-${i}`}>
                  <div>
                    {!item.notDelete && (
                      <button
                        style={{ background: "#bf291a" }}
                        onClick={() => deleteCarrinho(i)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>
                  <div>
                    <h3>1x</h3>
                  </div>
                  <div>{currencyFormatter(parseFloat(`${item.valor}`))}</div>
                  <div>
                    <p>
                      <b>Setor:</b> {item.setor?.nome} (
                      {item.setor?.tipo ? getTipoSetor(item.setor?.tipo) : ""})
                    </p>
                    <p>
                      <b>Lote:</b> {item.nrLote}
                    </p>
                    {item.nr_lugar && item?.nr_lugar > 0 && (
                      <p>
                        <b>
                          Nrº da {item.setor?.tipo === 3 ? "Vaga" : "Mesa"}:
                        </b>{" "}
                        {item.nr_lugar}
                      </p>
                    )}
                    <p>
                      <b>Valor:</b>{" "}
                      {currencyFormatter(parseFloat(`${item.valor}`))}
                    </p>
                  </div>
                </div>
              ))}

              <div className="faixaTotal">
                <p>
                  Total: {currencyFormatter(parseFloat(`${totalCarrinho}`))}
                </p>
                <button onClick={() => handleComprar()}>
                  <FontAwesomeIcon icon={faCashRegister} />
                  COMPRAR
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
