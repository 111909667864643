import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.css'
import { ISorteio } from '../../global/types'
import { api } from '../../services/api'

type IProps = {
  closeModal: () => void
  item: ISorteio
}

export function SorteioView({ closeModal, item }: IProps) {
  return (
    <div id="modalSorteioView">
      <div className="title">
        <p>Sorteio</p>
        <FontAwesomeIcon
          icon={faTimes}
          style={{ cursor: 'pointer' }}
          onClick={closeModal}
        />
      </div>
      <div className="body">
        <div>
          <img
            src={`${api.defaults.baseURL}/fotos/${item.foto}`}
            alt={`${api.defaults.baseURL}/fotos/${item.foto}`}
            width={250}
            height={250}
          />
        </div>
        <div>
          <p>
            <i>Nome:</i> {item.nome_completo}
          </p>
          <p>
            <i>CPF/RG:</i> {item.documento}
          </p>
          <p>
            <i>Telefone:</i> {item.telefone}
          </p>
          <p>
            <i>Tamanho:</i> {item.tamanho}
          </p>
          <p>
            <i>Dt.Nascimento:</i> {item.data_nascimento}
          </p>
          <p>
            <i>Sexo:</i> {item.sexo}
          </p>
        </div>
      </div>
    </div>
  )
}
