import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { ICreateUsuario, IUsuario } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";

import "./style.css";

type IProps = {
  tipoForm: "Edit" | "New";
}

type IParams = {
  idUsuario: string;
}

export function UsuarioFormAdm({ tipoForm }: IProps) {
  const navigate = useNavigate();
  const { idUsuario } = useParams() as IParams;
  const { tokens, checkToken, usuario } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [cadastro, setCadastro] = useState<ICreateUsuario>({} as ICreateUsuario);

  const loadCadastro = async () => {
    setLoading(true);
    setTxtLoading("Carregando cadastro...");

    const canLoad = checkToken();
    if (!canLoad)
      return;

    await api.get(`users/${idUsuario}`)
      .then((res) => {
        if (!res.data)
          return;

        const cad = res.data as IUsuario;

        setCadastro({
          id: cad.id,
          nome: cad.nome,
          user: cad.user,
          admin: cad.admin
        });

      })
      .catch((err) => {
        if (err.response?.data?.message)
          alert(err.response?.data?.message);
        else
          alert("Erro ao tentar recuperar o usuário. Tente novamente mais tarde.");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });

  };

  const handleSave = async () => {
    setLoading(true);
    setTxtLoading("Salvando usuário...");

    try {

      if (cadastro.nome.length < 3 || cadastro.user.length <= 3)
        return alert("Preencha todos os campos corretamente");

      if (!cadastro.id && (!cadastro.senha || cadastro.senha.length <= 2))
        return alert("Preencha o campo senha corretamente");

      const canLoad = checkToken();
      if (!canLoad)
        return;

      await api.post(`users`, { ...cadastro });

      navigate(-1);

    } catch (err: any) {
      if (err.response?.data?.message)
        alert(err.response.data.message);
      else
        alert("Erro ao salvar usuário. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
      setTxtLoading("");
    }
  }

  useEffect(() => {
    if (tipoForm === "Edit" && tokens?.token)
      loadCadastro();
  }, [tipoForm, tokens]);

  return (
    <div id="pageCidadesForm">

      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && usuario?.admin && (
        <div className="body">

          <span style={{ width: "49%" }}>
            <label>Nome</label>
            <Input
              mask="none"
              value={cadastro.nome}
              inputMaskChange={(v) => setCadastro(e => ({ ...e, nome: v }))}
            />
          </span>

          <span style={{ width: "49%" }}>
            <label>Usuário</label>
            <Input
              mask="none"
              value={cadastro.user}
              inputMaskChange={(v) => setCadastro(e => ({ ...e, user: v }))}
            />
          </span>

          <span style={{ width: "49%" }}>
            <label>Senha</label>
            <Input
              type="password"
              mask="none"
              value={cadastro.senha}
              inputMaskChange={(v) => setCadastro(e => ({ ...e, senha: v }))}
              placeholder={tipoForm === "Edit" ? "Para não alterar a senha deixe esse campo vazio" : ""}
            />
          </span>

          <span style={{ width: "49%" }}>
            <label>Admin?</label>
            <select value={cadastro.admin ? "1" : "0"} onChange={(e) => setCadastro(c => ({ ...cadastro, admin: e.target.value === "1" ? true : false }))}>
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </select>
          </span>

          <span className="btnContainer">
            <button className="btn-green" onClick={() => handleSave()}>
              <FontAwesomeIcon icon={faSave} />
              SALVAR
            </button>

            <button className="btn-purple" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faUndo} />
              CANCELAR
            </button>
          </span>

        </div>
      )}

    </div>
  )
}