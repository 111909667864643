import {
  faAddressCard,
  faCreditCard,
  faIdCard,
  faMap
} from "@fortawesome/free-regular-svg-icons"
import {
  faAsterisk,
  faAt,
  faCalendarDay,
  faCancel,
  faCheck,
  faCircleDollarToSlot,
  faListOl,
  faLocationArrow,
  faLocationDot,
  faPlusCircle,
  faSignsPost,
  faStreetView,
  faTrash
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { validate } from "gerador-validador-cpf"
import { useEffect, useState } from "react"
import { Load } from "../../components/Load"
import { MinhaContaLayout } from "../../components/MinhaContaLayout"
import {
  IClientes,
  IConfigSocioTorcedor,
  ICreditCard,
  ISocio
} from "../../global/types"
import {
  ICardPagarMe,
  ICreateCardPagarMe,
  ICustomerListPagarMe,
  ICustomersPagarMe
} from "../../global/types/pagarMe"
import { useSiteContext } from "../../hooks/useSiteContext"
import { api } from "../../services/api"
import { apiPagarMe } from "../../services/apiPagarMe"
import { currencyFormatter } from "../../utils/currencyFormatter"
import { convertDateToBr, dateNow } from "../../utils/dateProvider"
import { listUf } from "../../utils/listUf"
import { maskCep, maskCpfCnpj, maskCreditCard } from "../../utils/masks"
import { retirarCaracteresEsp } from "../../utils/retirarCaracteresEsp"
import { sleep } from "../../utils/sleep"
import "./style.css"

type ICadeiras = {
  nrCadeira: number
  disponivel: boolean
}

export function SocioTorcedor() {
  const { cliente, contextLoaded } = useSiteContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [socio, setSocio] = useState<ISocio | undefined>()
  const [socioLoaded, setSocioLoaded] = useState(false)

  const [section, setSection] = useState<
    | ""
    | "cadPagarMe"
    | "termo"
    | "listCartoes"
    | "cadCartao"
    | "listCadeiras"
    | "viewSocio"
  >("")

  const [configSocio, setConfigSocio] = useState<
    IConfigSocioTorcedor | undefined
  >()
  const [cadCliente, setCadCliente] = useState<IClientes | undefined>()
  const [listCadeiras, setListCadeiras] = useState<ICadeiras[]>([])

  const [cartaoSelected, setCartaoSelected] = useState<
    ICardPagarMe | undefined
  >()
  const [cadeiraSelected, setCadeiraSelected] = useState<
    ICadeiras | undefined
  >()

  const [listCartoes, setListCartoes] = useState<ICardPagarMe[]>([])

  // CAD PAGAR.ME
  const [clienteDocumento, setClienteDocumento] = useState("")
  const [clienteEmail, setClienteEmail] = useState("")

  // CADASTRO CARTAO DE CREDITO
  const [creditCard, setCreditCard] = useState<ICreditCard>({
    cvv: "",
    exp_month: "",
    exp_year: "",
    holder_name: "",
    number: ""
  })
  const [endereco, setEndereco] = useState("")
  const [nr, setNr] = useState("")
  const [bairro, setBairro] = useState("")
  const [complemento, setComplemento] = useState("")
  const [cep, setCep] = useState("")
  const [cidade, setCidade] = useState("")
  const [estado, setEstado] = useState<string>("SP")

  const loadConfigSocio = async () => {
    setLoading(true)
    setTxtLoading("Carregando informações sobre o Sócio Torcedor")

    await api
      .get("/configSocioTorcedor")
      .then((res) => {
        if (!res.data)
          throw new Error("Servidor indisponível. Tente novamente mais tarde")

        setConfigSocio(res.data)
        verificaSocio()
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else alert("Erro ao carregar informações. Tente novamente mais tarde")

        setLoading(false)
        setTxtLoading("")
      })
  }

  const verificaSocio = async () => {
    setLoading(true)
    setTxtLoading("Veriricando assinatura")

    await api
      .get(`socio/cliente/${cliente?.id}`)
      .then((res) => {
        const result = res.data as ISocio

        if (result !== null && result.id && result.id.length > 0) {
          setSocio(result)
          setSection("viewSocio")
        } else setSection("termo")
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else alert("Erro ao verificar cliente. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
        setSocioLoaded(true)
      })
  }

  const handleAceito = async () => {
    setLoading(true)
    setTxtLoading("Verificando seu cadastro...")

    await api
      .get(`clientes/${cliente?.id}`)
      .then((res) => {
        if (!res.data)
          throw new Error("Servidor indisponível. Tente novamente mais tarde")

        const nCadCliente: IClientes = res.data

        setCadCliente(nCadCliente)

        if (!nCadCliente.id_pagar_me) setSection("cadPagarMe")
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else
          alert(
            "Erro ao carregar cadastro do cliente. Tente novamente mais tarde"
          )
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }

  const handleFindOrCreatePagarMe = async () => {
    setLoading(true)
    setTxtLoading("Buscando informações do cliente...")

    if (clienteDocumento.length !== 14 || !validate(clienteDocumento))
      return alert("Digite um CPF válido")

    if (clienteEmail.length <= 5 || !clienteEmail.includes("@"))
      return alert("Digite um e-mail válido")

    const cpf = parseInt(clienteDocumento.replace(/[^0-9]/g, ""))

    try {
      const reqGetCustomer = await apiPagarMe.get(`customers?document=${cpf}`)
      if (!reqGetCustomer.data)
        throw new Error("Servidor indisponível. Tente novamente mais tarde")

      const result: ICustomerListPagarMe = reqGetCustomer.data

      if (result.paging.total > 0) {
        setTxtLoading("Salvando informações do cliente...")
        const reqEditCliente = await api.post("clientes", {
          ...cadCliente,
          id_pagar_me: result.data[0].id
        })
        if (!reqEditCliente.data)
          throw new Error("Servidor indisponível. Tente novamente mais tarde")

        if (cadCliente) {
          const newCliente: IClientes = {
            ...cadCliente,
            id_pagar_me: result.data[0].id
          }
          setCadCliente(newCliente)
        }
      } else {
        const reqCadPagarMe = await apiPagarMe.post("customers", {
          name: cadCliente?.nome,
          email: clienteEmail,
          document: cpf,
          document_type: "CPF",
          type: "individual",
          phones: {
            mobile_phone: {
              country_code: "55",
              area_code: cadCliente?.telefone.substring(0, 2),
              number: cadCliente?.telefone.substring(2)
            }
          }
        })
        if (!reqCadPagarMe.data)
          throw new Error("Servidor indisponível. Tente novamente mais tarde")

        const result: ICustomersPagarMe = reqGetCustomer.data

        const reqEditCliente = await api.post("clientes", {
          ...cadCliente,
          id_pagar_me: result.id
        })
        if (!reqEditCliente.data)
          throw new Error("Servidor indisponível. Tente novamente mais tarde")

        if (cadCliente) {
          const newCliente: IClientes = {
            ...cadCliente,
            id_pagar_me: result.id
          }
          setCadCliente(newCliente)
        }
      }
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert("Erro ao salvar informações. Tente novamente mais tarde")
    } finally {
      setLoading(false)
      setTxtLoading("")
    }
  }

  const loadCartoes = async () => {
    if (!cadCliente || !cadCliente?.id_pagar_me) return

    setSection("listCartoes")
    setLoading(true)
    setTxtLoading("Recuperando lista de cartões cadastrados...")

    await apiPagarMe(`customers/${cadCliente.id_pagar_me}/cards`)
      .then((res) => {
        if (!res.data)
          throw new Error("Servidor indisponível. Tente novamente mais tarde")

        setListCartoes(res.data.data)
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else
          alert(
            "Erro ao recuperar lista de cartões. Tente novamente mais tarde"
          )
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }

  const handleSalvaCartaoCredito = async () => {
    if (creditCard.exp_year.length !== 4)
      return alert("Preencha o ano da validade do cartão com 4 dígitos.")

    if (
      creditCard.number.length !== 19 ||
      creditCard.cvv.length < 3 ||
      creditCard.exp_month.length !== 2 ||
      creditCard.exp_year.length !== 4 ||
      creditCard.holder_name.length <= 3
    )
      return alert("Preencha todos os dados do cartão corretamente.")

    setLoading(true)
    setTxtLoading("Cadastrando cartão de crédito...")

    const newCard: ICreateCardPagarMe = {
      number: creditCard.number.replace(/[^0-9]/g, ""),
      holder_name: retirarCaracteresEsp(creditCard.holder_name),
      exp_month: parseInt(creditCard.exp_month),
      exp_year: parseInt(creditCard.exp_year),
      cvv: creditCard.cvv,
      billing_address: {
        line_1: `${nr.replace(",", "").replace(".", "")}, ${endereco.replace(
          ",",
          ""
        )}, ${bairro.replace(",", "")}`,
        line_2: complemento,
        zip_code: cep.replace(/\D/g, ""),
        city: cidade,
        state: estado,
        country: "br"
      }
    }

    await apiPagarMe
      .post(`customers/${cadCliente?.id_pagar_me}/cards`, newCard)
      .then((res) => {
        if (!res.data)
          throw new Error("Servidor indisponível. Tente novamento mais tarde")
      })
      .catch((err) => {
        if (err.response?.data?.message) alert(err.response?.data?.message)
        else
          alert(
            "Erro ao cadastrar o cartão de crédito. Tente novamente mais tarde"
          )
      })
      .finally(() => {
        loadCartoes()
      })
  }

  const handleDeleteCard = async (item: ICardPagarMe) => {
    if (
      !window.confirm(
        `Deseja realmente excluir o cartão de crédito com o final ${item.last_four_digits}?`
      )
    )
      return

    setLoading(true)
    setTxtLoading("Excluindo cartão de crédito...")

    await apiPagarMe.delete(
      `customers/${cadCliente?.id_pagar_me}/cards/${item.id}`
    )

    loadCartoes()
  }

  const handleSelectCard = async (item: ICardPagarMe) => {
    if (!configSocio?.maximo_socios) return

    setLoading(true)
    setTxtLoading("Carregando cadeiras restantes...")

    setSection("listCadeiras")
    setCartaoSelected(item)

    try {
      const reqListCadeiras = await api.get(
        `socio/?cancelados=notCanceled&limit=${configSocio?.maximo_socios}`
      )
      if (!reqListCadeiras.data)
        throw new Error("Servidor indisponível. Tente novamento mais tarde")

      const list: ISocio[] = reqListCadeiras.data.list
      const cadeirasUsados: number[] = []
      const newList: ICadeiras[] = []

      list.forEach((e) => {
        cadeirasUsados.push(e.nr_cadeira)
      })

      const qtdLinhas = configSocio.maximo_socios / configSocio.qtd_linha

      for (let y = qtdLinhas; y > 0; y--) {
        for (let i = 1; i <= configSocio.qtd_linha; i++) {
          const nr = i + (configSocio.qtd_linha * y - 40)
          newList.push({
            nrCadeira: nr,
            disponivel: !cadeirasUsados.includes(nr)
          })
        }
      }

      setListCadeiras(newList)

      setLoading(false)
      setTxtLoading("")
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else
        alert(
          "Erro ao buscar lista de cadeiras disponíveis. Tente novamente mais tarde"
        )
    }
  }

  const handleAssinar = async () => {
    if (
      !window.confirm(
        "Deseja realmente realizar a assinatura do Sócio Torcedor?"
      )
    )
      return

    setLoading(true)
    setTxtLoading("Assinando...")

    try {
      const reqPagarMe = await apiPagarMe.post("subscriptions", {
        currency: "BRL",
        payment_method: "credit_card",
        interval: "month",
        interval_count: 1,
        billing_type: "exact_day",
        billing_day: new Date().getDate(),
        installments: 1,
        customer_id: cadCliente?.id_pagar_me,
        card_id: cartaoSelected?.id,
        statement_descriptor: "SocioTorcedor",
        items: [
          {
            pricing_scheme: {
              scheme_type: "Unit",
              price: parseInt(
                currencyFormatter(parseFloat(`${configSocio?.valor}`)).replace(
                  /\D/g,
                  ""
                )
              )
            },
            description: "SócioTorcedor",
            quantity: 1
          }
        ]
      })
      if (!reqPagarMe.data)
        throw new Error("Servidor indisponível. Tente novamento mais tarde")

      const idAssinatura = reqPagarMe.data.id
      const idItem = reqPagarMe.data.items[0].id
      let status: "active" | "canceled" | "future" | "failed" =
        reqPagarMe.data.status

      if (status === "failed")
        throw new Error("Erro ao tentar efetuar o pagamento")

      ;[1, 2, 3].forEach(async (itemTry) => {
        if (status !== "active") {
          setTxtLoading(`Tentativa de pagamento ${itemTry}/3`)
          await sleep(2000)

          const reqTry1 = await apiPagarMe.get(`subscriptions/${idAssinatura}`)
          if (!reqTry1.data)
            throw new Error("Servidor indisponível. Tente novamento mais tarde")
          status = reqTry1.data.status

          if (status === "failed")
            throw new Error("Erro ao tentar efetuar o pagamento")
        }
      })

      const reqAddSocio = await api.post("socio", {
        id_cliente: cliente?.id,
        id_assinatura: idAssinatura,
        id_item: idItem,
        nr_cadeira: cadeiraSelected?.nrCadeira,
        data_inicio: dateNow(),
        valor_atual: configSocio?.valor,
        dia_cobranca: new Date().getDate()
      })
      if (!reqAddSocio.data)
        throw new Error("Servidor indisponível. Tente novamento mais tarde")

      verificaSocio()
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else if (err.message) alert(err.message)
      else alert("Erro ao efetuar a assinatura. Tente novamente mais tarde")

      setLoading(false)
      setTxtLoading("")
    }
  }

  const handleCancelAssinatura = async () => {
    if (!window.confirm("Deseja realmente cancelar sua assinatura?")) return

    setLoading(true)
    setTxtLoading("Cancelando Assinatura...")

    try {
      const reqCancelPagarMe = await apiPagarMe.delete(
        `subscriptions/${socio?.id_assinatura}`
      )
      if (!reqCancelPagarMe.data)
        throw new Error("Servidor indisponível. Tente novamente mais tarde")

      const dataReq = { ...socio, data_cancelamento: dateNow() }
      const reqCancelAssinatura = await api.post("socio", { ...dataReq })
      if (!reqCancelAssinatura.data)
        throw new Error(
          "Servidor ticket capital indisponível. Tente novamente mais tarde"
        )

      alert("Assinatura cancelada com sucesso!")

      verificaSocio()
    } catch (err: any) {
      if (err.response?.data?.message) alert(err.response?.data?.message)
      else alert("Erro ao cancelar a assinatura. Tente novamente mais tarde")

      setLoading(false)
      setTxtLoading("")
    }
  }

  useEffect(() => {
    if (contextLoaded) loadConfigSocio()
  }, [contextLoaded])

  useEffect(() => {
    if (cadCliente && cadCliente.id_pagar_me) loadCartoes()
  }, [cadCliente])

  return (
    <MinhaContaLayout>
      <div className="socioPage">
        {loading && (
          <Load txtLoading={txtLoading} options={{ height: 150, width: 150 }} />
        )}

        {!loading && socioLoaded && (
          <>
            {!socio && (
              <>
                {/* NORMAS E CONDIÇÕES DO SÓCIO TORCEDOR  */}
                {section === "termo" && (
                  <>
                    <h3>Você ainda não é um Sócio Torcedor</h3>
                    <h3>ASSINE AGORA</h3>
                    <div className="priceSocio">
                      <p>
                        {currencyFormatter(
                          configSocio?.valor
                            ? parseFloat(`${configSocio.valor}`)
                            : 10
                        )}
                      </p>
                      <small>por mês</small>
                    </div>

                    <div className="termoContent">
                      <h3 style={{ margin: "12px" }}>
                        Normas e Condições do Sócio Torcedor
                      </h3>
                      {configSocio?.termo}
                    </div>

                    <div className="btnAceito" onClick={() => handleAceito()}>
                      <h4>ACEITO</h4>
                      <p>as codições</p>
                    </div>
                  </>
                )}

                {/* INFORME CPF E EMAIL - CAD PAGAR.ME */}
                {section === "cadPagarMe" && (
                  <>
                    <h3>Informe seu CPF e E-mail</h3>
                    <div className="inputForm">
                      <FontAwesomeIcon icon={faAddressCard} />
                      <input
                        value={clienteDocumento}
                        onChange={(e) =>
                          setClienteDocumento(maskCpfCnpj(e.target.value))
                        }
                        placeholder="CPF"
                        type="tel"
                        maxLength={14}
                      />
                    </div>

                    <div className="inputForm">
                      <FontAwesomeIcon icon={faAt} />
                      <input
                        value={clienteEmail}
                        onChange={(e) => setClienteEmail(e.target.value)}
                        placeholder="E-Mail"
                        type="email"
                      />
                    </div>

                    <button
                      className="btnAceito"
                      style={{ alignSelf: "center", flexDirection: "row" }}
                      onClick={() => handleFindOrCreatePagarMe()}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      SALVAR
                    </button>
                  </>
                )}

                {/* LISTA CARTOES */}
                {section === "listCartoes" && (
                  <>
                    <h3>Selecione o cartão que deseja usar na assinatura</h3>
                    <FontAwesomeIcon
                      className="btnAddCard"
                      icon={faPlusCircle}
                      onClick={() => setSection("cadCartao")}
                    />
                    <div className="listCards">
                      {listCartoes.map((item) => (
                        <div className="itemCard" key={item.id}>
                          <div
                            className="btnDelete"
                            onClick={() => handleDeleteCard(item)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                          <div onClick={() => handleSelectCard(item)}>
                            <p>
                              {item.first_six_digits.substring(0, 4)} **** ****{" "}
                              {item.last_four_digits}
                            </p>
                            <p style={{ textAlign: "right", fontSize: "16px" }}>
                              {item.brand}
                            </p>
                            <p style={{ fontSize: "12px" }}>
                              Venc.: {("00" + item.exp_month).slice(-2)}/
                              {item.exp_year}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {/* CADASTRO DE CARTÃO */}
                {section === "cadCartao" && (
                  <div className="formCartao">
                    <h3>CADASTRO DE CARTÃO DE CRÉDITO</h3>

                    <div className="inputForm">
                      <FontAwesomeIcon icon={faCreditCard} />
                      <input
                        value={creditCard.number}
                        onChange={(e) =>
                          setCreditCard((s) => ({
                            ...s,
                            number: maskCreditCard(e.target.value)
                          }))
                        }
                        placeholder="Número do cartão"
                        type="tel"
                        maxLength={19}
                      />
                    </div>

                    <div className="inputForm">
                      <FontAwesomeIcon icon={faIdCard} />
                      <input
                        value={creditCard.holder_name}
                        onChange={(e) =>
                          setCreditCard((s) => ({
                            ...s,
                            holder_name: e.target.value
                          }))
                        }
                        placeholder="Nome do títular do cartão"
                      />
                    </div>

                    <div className="inputForm">
                      <FontAwesomeIcon icon={faAsterisk} />
                      <input
                        value={creditCard.cvv}
                        onChange={(e) =>
                          setCreditCard((s) => ({ ...s, cvv: e.target.value }))
                        }
                        placeholder="CVV"
                        type="tel"
                        maxLength={4}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: "300px"
                      }}
                    >
                      <p
                        style={{
                          width: "100%",
                          fontWeight: "600",
                          padding: "0px 10px"
                        }}
                      >
                        Validade do cartão: <small>(ex: 09/2030)</small>{" "}
                      </p>
                      <div
                        className="inputForm"
                        style={{ flex: 1, width: "145px" }}
                      >
                        <FontAwesomeIcon icon={faCalendarDay} />
                        <input
                          value={creditCard.exp_month}
                          onChange={(e) =>
                            setCreditCard((s) => ({
                              ...s,
                              exp_month: e.target.value
                            }))
                          }
                          placeholder="DD"
                          type="number"
                          max={12}
                        />
                      </div>

                      <div
                        className="inputForm"
                        style={{ flex: 1, width: "145px" }}
                      >
                        <FontAwesomeIcon icon={faCalendarDay} />
                        <input
                          value={creditCard.exp_year}
                          onChange={(e) =>
                            setCreditCard((s) => ({
                              ...s,
                              exp_year: e.target.value
                            }))
                          }
                          placeholder="AAAA"
                          type="number"
                          max={9999}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: "300px"
                      }}
                    >
                      <p
                        style={{
                          width: "100%",
                          fontWeight: "600",
                          padding: "0px 10px"
                        }}
                      >
                        Endereço de Cobrança:
                      </p>
                      <div className="inputForm">
                        <FontAwesomeIcon icon={faLocationArrow} />
                        <input
                          value={endereco}
                          onChange={(e) => setEndereco(e.target.value)}
                          placeholder="Endereço de cobrança"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: "300px"
                      }}
                    >
                      <div
                        className="inputForm"
                        style={{ flex: 1, width: "145px" }}
                      >
                        <FontAwesomeIcon icon={faListOl} />
                        <input
                          value={nr}
                          onChange={(e) => setNr(e.target.value)}
                          placeholder="Número"
                        />
                      </div>

                      <div
                        className="inputForm"
                        style={{ flex: 1, width: "145px" }}
                      >
                        <FontAwesomeIcon icon={faSignsPost} />
                        <input
                          value={bairro}
                          onChange={(e) => setBairro(e.target.value)}
                          placeholder="Bairro"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: "300px"
                      }}
                    >
                      <div className="inputForm">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <input
                          value={complemento}
                          onChange={(e) => setComplemento(e.target.value)}
                          placeholder="Complemento"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: "300px"
                      }}
                    >
                      <div className="inputForm">
                        <FontAwesomeIcon icon={faMap} />
                        <input
                          value={cidade}
                          onChange={(e) => setCidade(e.target.value)}
                          placeholder="Cidade"
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: "300px"
                      }}
                    >
                      <div className="inputForm">
                        <FontAwesomeIcon icon={faStreetView} />
                        <select
                          value={estado}
                          onChange={(e) => setEstado(e.target.value)}
                          style={{ padding: "0px" }}
                        >
                          {listUf.map((item) => (
                            <option value={`${item.uf}`}>{item.nome}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                        width: "300px"
                      }}
                    >
                      <div className="inputForm">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <input
                          value={cep}
                          onChange={(e) => setCep(maskCep(e.target.value))}
                          placeholder="CEP"
                          type="tel"
                          maxLength={9}
                        />
                      </div>
                    </div>

                    <button
                      className="btnAceito"
                      style={{ alignSelf: "center", flexDirection: "row" }}
                      onClick={() => handleSalvaCartaoCredito()}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      SALVAR
                    </button>
                  </div>
                )}

                {/* LISTA DE CADEIRAS */}
                {section === "listCadeiras" && (
                  <>
                    <h3>Selecione uma cadeira vaga</h3>
                    <div className="infoArquibancada">
                      <p>
                        Cada quadrado representa uma cadeira na arquibancada
                      </p>
                      <p>Cadeiras disponíveis são as de cores mais fortes</p>
                    </div>

                    <div className="gramado">
                      <h4>GRAMADO</h4>
                    </div>

                    <div className="cadeirasContent">
                      <div className="listCadeiras">
                        {listCadeiras.map((item, i) => (
                          <div
                            onClick={() => setCadeiraSelected(item)}
                            className="btnCadeira"
                            key={`cadeira-${i}`}
                            style={{
                              // opacity: item.disponivel ? 1 : 1,
                              minWidth: `calc((100% / ${configSocio?.qtd_linha}) - 5px )`,
                              background: item.disponivel
                                ? cadeiraSelected?.nrCadeira === item.nrCadeira
                                  ? "#a8c924"
                                  : "#6f66ff"
                                : "#9f1616"
                            }}
                            title={`${item.nrCadeira}`}
                          ></div>
                        ))}
                      </div>
                    </div>

                    <div className="acesso">
                      <h4>ACESSO</h4>
                    </div>

                    {cadeiraSelected && (
                      <>
                        <h3>VERIFIQUE TODAS AS INFORMAÇÕES:</h3>

                        <div className="ConfirmAssinatura">
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center"
                            }}
                          >
                            <h3>
                              Cadeira selecionada: {cadeiraSelected.nrCadeira}
                            </h3>
                            <div
                              style={{
                                color: cadeiraSelected.disponivel
                                  ? "green"
                                  : "red",
                                border: `2px dashed ${
                                  cadeiraSelected.disponivel ? "green" : "red"
                                }`,
                                padding: "8px 16px",
                                borderRadius: "8px"
                              }}
                            >
                              {cadeiraSelected.disponivel
                                ? "DISPONÍVEL"
                                : "INDISPONÍVEL"}
                            </div>
                          </div>

                          <div style={{ flex: 1 }}>
                            <h3>Cartão selecionado:</h3>
                            <p>
                              {cartaoSelected?.first_six_digits.substring(0, 4)}{" "}
                              **** **** {cartaoSelected?.last_four_digits}
                            </p>
                          </div>
                        </div>

                        {cadeiraSelected.disponivel && (
                          <button
                            className="btnAceito"
                            onClick={() => handleAssinar()}
                          >
                            <FontAwesomeIcon icon={faCircleDollarToSlot} />
                            ASSINAR
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {/* VIEW SOCIO */}
            {socio && section === "viewSocio" && (
              <>
                <h3>Sócio Torcedor</h3>

                <div className="infoSocio">
                  <div>
                    <b>Nrº Cadeira:</b> {socio.nr_cadeira}
                  </div>

                  <div>
                    <b>Assinante desde:</b> {convertDateToBr(socio.data_inicio)}
                  </div>

                  <div>
                    <b>Valor próxima fatura:</b>{" "}
                    {currencyFormatter(parseFloat(`${socio.valor_atual}`))}
                  </div>

                  <div>
                    <b>Dia da Cobrança:</b> {socio.dia_cobranca}
                  </div>
                </div>

                <button
                  className="cancelAssinatura"
                  onClick={() => {
                    handleCancelAssinatura()
                  }}
                >
                  <FontAwesomeIcon icon={faCancel} />
                  CANCELAR ASSINATURA
                </button>
              </>
            )}
          </>
        )}
      </div>
    </MinhaContaLayout>
  )
}
